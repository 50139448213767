import { Maybe, PreloadCacheDocument } from '~/api/generated/graphql'
import { AuthRegistrationRequest, AuthServerResponse, AuthStatus } from '~/api/types'

let _api_base: Maybe<string>
let _cdn_base: Maybe<string>

export const getApiBase = () => {
  if (_api_base === undefined) {
    const url = new URL(JSON.parse(document.getElementById('data/API_BASE')?.textContent ?? '""'), location.href)
    _api_base = String(url).replace(/\/$/, '')
  }
  return _api_base as string
}

export const getCdnBase = () => {
  if (_cdn_base === undefined) {
    const url = new URL(JSON.parse(document.getElementById('data/CDN_BASE')?.textContent ?? '""'), location.href)
    _cdn_base = String(url).replace(/\/$/, '')
  }
  return _cdn_base as string
}

export type AuthData = {
  userId?: string
  preUserId?: string
  profileVisible: boolean
  isVeevan: boolean
  email: string
  photo: string
  firstName: string
  lastName: string
  nickName: string
  company: {
    companyId: string
    name: string
  }
  permissions: {
    canEditIds: string[]
    canPostIds: string[]
    sysAdminId: string
    relAdminId: string
    summitAdminId: string
  }
  isLastKnownUser?: boolean
  disableWebsocketServer?: boolean
  baseUrl?: string
}

export const getAuth = (): AuthData | undefined => {
  const element = document.getElementById('data/AUTH')
  const authData = element?.textContent
  const result = authData ? JSON.parse(authData) : undefined

  if (element) element.remove()
  return result
}

export const clearStorageItems = () => {
  localStorage.removeItem('FEED_TAB')
  localStorage.removeItem('COMMUNITY_TAB')
  localStorage.removeItem('RECENT_SEARCH_SELECTION')
}

const getSession = () => JSON.parse(document.getElementById('data/SESSION')?.textContent ?? '""')

export const logout = (): Promise<void> => {
  return fetch(new URL('/auth/logout', getApiBase()), {
    method: 'POST',
    headers: { 'x-session-id': getSession() },
    credentials: 'include',
  }).then()
}

export const authStart = async (email: string): Promise<AuthServerResponse> => {
  const args = { ...(email && { email }) }

  const resp = await fetch(new URL(`/auth/start?${new URLSearchParams(args)}`, getApiBase()), {
    headers: { 'x-session-id': getSession() },
    credentials: 'include',
  })
  if (resp.ok) {
    return await resp.json()
  } else {
    const text = await resp.text()
    if (text.includes('Too Many Requests')) {
      return { authStatus: AuthStatus.emailAccepted, resendDelay: 3000, resendDelayText: '30 seconds' }
    }
    return { authStatus: AuthStatus.error }
  }
}

export const authVerify = async (email: string, code: string): Promise<AuthServerResponse> => {
  const nums = code.match(/\d/g)
  if (nums) {
    const numCode = nums.join('')
    if (numCode.length == 6) {
      const args = {
        email,
        code: numCode,
      }

      const resp = await fetch(new URL('/auth/verify', getApiBase()), {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(args),
        headers: {
          'Content-Type': 'application/json',
          'x-session-id': getSession(),
        },
      })
      if (resp.ok) {
        return await resp.json()
      } else {
        const text = await resp.text()
        if (text.includes('Too Many Requests')) {
          return { authStatus: AuthStatus.codeRejected, verifyDelay: 3000, verifyDelayText: '30 seconds' }
        }
        return { authStatus: AuthStatus.error }
      }
    }
  }
  return { authStatus: AuthStatus.codeMalformed }
}

export const authRegister = async (registrationRequest: AuthRegistrationRequest): Promise<string> => {
  const args = {
    email: registrationRequest.email,
    firstName: registrationRequest.firstName,
    lastName: registrationRequest.lastName,
    nickName: registrationRequest.nickName,
    title: registrationRequest.title,
    profileVisible: registrationRequest.profileVisible,
    aboutMe: registrationRequest.aboutMe,
    roleAtCompany: registrationRequest.roleAtCompany,
    linkUrl: registrationRequest.linkUrl,
  }
  const resp = await fetch(new URL('/auth/register', getApiBase()), {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(args),
    headers: {
      'Content-Type': 'application/json',
      'x-session-id': getSession(),
    },
  })
  if (resp.status === 202) {
    return resp.status.toString()
  } else {
    return resp.text()
  }
}

export const exportHistoryCsv = async (start_date: string, end_date: string, file_name: string): Promise<Response> => {
  return await fetch(
    new URL(
      `/export/history?${new URLSearchParams({
        start_date: start_date,
        end_date: end_date,
        file_name: file_name,
      })}`,
      getApiBase()
    ),
    { credentials: 'include' }
  )
}

export const getLinkedinPhoto = async (): Promise<{ profile_image: string }> => {
  const resp = await fetch(new URL('/linkedin/photo', getApiBase()), {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-session-id': getSession(),
    },
  })

  return resp.json()
}

// Requests that need to hit the CDN

export const startup = (): Promise<Response> =>
  fetch(new URL('/startup', getCdnBase()), {
    credentials: 'include',
    method: 'GET',
    headers: {
      'x-session-id': getSession(),
    },
  })

export const preloadCache = (): Promise<Response> => {
  const url = new URL('/preload/cache', getCdnBase())
  const NONCE = JSON.parse(document.getElementById('data/NONCE')?.textContent ?? '""')
  url.searchParams.append('nonce', NONCE)
  const preloadCacheDocumentElement = (PreloadCacheDocument as unknown as { __meta__: { hash: string } })['__meta__']
  url.searchParams.append('q', preloadCacheDocumentElement['hash'])
  return fetch(url, {
    credentials: 'include',
    method: 'GET',
  })
}
