import React from 'react'
import { Dropdown } from 'react-bootstrap'

type EventDropdownItemsProps = {
  eventId: string
  onDelete?: (e?: React.MouseEvent) => void
  onClickEdit?: (e?: React.MouseEvent) => void
  onClickRepost?: (e?: React.MouseEvent) => void
  onClickCopy?: (e?: React.MouseEvent) => void
  canRepost?: boolean
  canEdit?: boolean
  canDelete?: boolean
  isRepost?: boolean
  showLivestreamToggle?: boolean
  livestreamStarted?: boolean
  canToggleLivestream?: boolean
  toggleLivestream?: () => void
}
export const EventDropdownItems = ({
  onDelete,
  onClickEdit,
  onClickRepost,
  onClickCopy,
  canRepost,
  canEdit,
  canDelete,
  isRepost,
  livestreamStarted,
  canToggleLivestream,
  toggleLivestream,
}: EventDropdownItemsProps) => {
  return (
    <>
      <Dropdown.Menu className={'event-dropdown-menu'}>
        <Dropdown.Item className={'copy-link'} onClick={onClickCopy}>
          Copy link to event
        </Dropdown.Item>
        {canEdit && (
          <Dropdown.Item className={'edit'} onClick={onClickEdit}>
            Edit
          </Dropdown.Item>
        )}
        {canDelete && (
          <Dropdown.Item className={'delete'} onClick={onDelete}>
            {isRepost ? 'Delete Repost' : 'Delete'}
          </Dropdown.Item>
        )}
        {canRepost && (
          <Dropdown.Item className={'repost'} onClick={onClickRepost}>
            Repost
          </Dropdown.Item>
        )}
        {canToggleLivestream && (
          <Dropdown.Item className={livestreamStarted ? 'toggle-stop' : 'toggle-start'} onClick={toggleLivestream}>
            {livestreamStarted ? 'Stop live stream' : 'Start live stream'}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </>
  )
}
