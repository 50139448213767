import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { EventCalendarIconComponent } from '~/common/EventCalendarIconComponent'
import {
  EditEventDocument,
  GetEventCommunityDocument,
  GetEventDocument,
  Maybe,
  SetHideEventRepostDocument,
  StartLivestreamDocument,
} from '~/api/generated/graphql'
import CommunityEventEdit from '~/pages/community/CommunityEventEdit'
import '@css/pages/community/CommunityEventRow.scss'
import { asDate, asEvent, asHtmlWithMentions, asString, formatLinks, getStartOfCurrentDay } from '~/utils'
import { Link } from 'react-router-dom'
import TimeAgo from '~/common/TimeAgo'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { KebabToggle } from '~/common/KebabToggle'
import { elementClicked } from '~/common/EventLogger'
import { EventDropdownItems } from '~/pages/community/EventDropdownItems'
import { useNavigate } from 'react-router'
import { EventRepostLink } from '~/common/RepostOverlay'
import { UserLink } from '~/common/UserLink'
import { useAuth } from '~/auth/Auth'
import MentionableText from '~/common/MentionableText'
import ToastComponent from '~/common/ToastComponent'
import { useMutation, useQuery } from '@apollo/client'
import { VideoPlayer } from '~/common/VideoPlayer'

const getEventTimeText = (start?: Date, end?: Date): string => {
  if (!start || !end) return ''
  // typescript complains when defining the localeString options object non-inline even if it is valid.
  // Using ts-ignore to ignore these errors since they make the code a little messier to eliminate
  const optionswDate = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' }
  const isSameDay = start.getDay() === end.getDay() && start.getMonth() === end.getMonth()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const startText = start.toLocaleString([], optionswDate)
  const endText = isSameDay
    ? end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      end.toLocaleString([], { ...optionswDate, timeZoneName: 'short' })
  return `${startText} - ${endText}`
}

type CommunityEventRowProps = {
  eventId?: Maybe<string>
  canViewInfo: boolean
  handleJoinCommunity?: (e: SyntheticEvent) => void
  canEdit: boolean
  communityId: string
  setToast?: (toast: string) => void
  fromRepostPage?: boolean
  isVeevan?: boolean
  canRepost: boolean
  onEditingChange?: (isEditing: boolean, eventId?: string | null) => void
  canDelete: boolean
  scrollToEvent?: string
  scrolled?: boolean
  setScrolled?: (b: boolean) => void
  currentLiveStreamEventId?: string
  joinLivestream?: () => void
  turnOffLivestream?: () => void
}

const CommunityEventRow = ({
  eventId,
  canViewInfo,
  handleJoinCommunity,
  fromRepostPage,
  communityId,
  setToast,
  canEdit,
  canRepost,
  onEditingChange,
  canDelete,
  isVeevan,
  scrollToEvent,
  scrolled,
  setScrolled,
  joinLivestream,
  currentLiveStreamEventId,
  turnOffLivestream,
}: CommunityEventRowProps) => {
  const { authUserId, actingSysAdmin } = useAuth()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const isLivestreamTab = currentLiveStreamEventId == eventId && !joinLivestream
  const { data: eventData } = useQuery(GetEventDocument, {
    variables: { id: eventId ?? '' },
    skip: !eventId,
    fetchPolicy: isLivestreamTab ? 'network-only' : 'cache-first', // we want any changes to the description if viewing the live stream
  })
  const event = eventData?.event
  const repostUserId = fromRepostPage ? authUserId : event?.createdById
  const repostCommunityId = fromRepostPage ? event?.communityId : event?.repost?.communityId
  const { data: eventCommunity } = useQuery(GetEventCommunityDocument, {
    variables: { id: event?.communityId ?? '' },
    skip: !event?.isLivestream,
  })

  const now = new Date()
  const start = new Date(event?.eventStart)
  const isFutureLivestream = start > now

  const liveStreamInProgress = event?.isLivestream && currentLiveStreamEventId == event?.eventId

  const { data: repostCommunity, loading } = useQuery(GetEventCommunityDocument, {
    variables: { id: repostCommunityId ?? '' },
    skip: !repostCommunityId,
  })

  const navigate = useNavigate()

  const reposts = event?.reposts?.filter(e => !e?.hidden).map(e => asEvent(e))
  const hasReposts = !!(!loading && reposts && reposts.length > 0)

  const repostedList = useMemo(() => {
    if (reposts?.length == 1) {
      return (
        <span>
          <EventRepostLink event={reposts[0]} />.
        </span>
      )
    } else if (reposts?.length == 2) {
      return (
        <span>
          <EventRepostLink event={reposts[0]} /> and <EventRepostLink event={reposts[1]} />.
        </span>
      )
    } else if (reposts?.length == 3) {
      return (
        <span>
          <EventRepostLink event={reposts[0]} />, <EventRepostLink event={reposts[1]} />
          , and <EventRepostLink event={reposts[2]} />.
        </span>
      )
    } else {
      return (
        <span>
          <EventRepostLink event={reposts?.[0]} />, <EventRepostLink event={reposts?.[1]} />, and{' '}
          <Link to={`./${eventId}/repost`}>{reposts?.length ? reposts?.length - 2 : 0} other</Link> communities.
        </span>
      )
    }
  }, [reposts, eventId])

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)
  const handleClickDelete = () => setShowDeleteDialog(true)
  const handleCancelDelete = () => setShowDeleteDialog(false)
  const handleClickEdit = (e: React.MouseEvent) => {
    elementClicked(e, 'click-community-event-edit', {
      community_id: communityId,
      event_id: event?.eventId,
    })
    setIsEditing(true)
    onEditingChange?.(true, eventId)
  }

  const singleEventPath = `/communities/${communityId}/events`
  const handleClickRepost = (e: React.MouseEvent) => {
    elementClicked(e, 'click-community-event-repost', {
      community_id: communityId,
      event_id: event?.eventId,
    })
    navigate(`${singleEventPath}/${event?.eventId}/repost`)
  }

  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const handleClickCopy = (e: React.MouseEvent) => {
    const permalink = `${document.location.origin}${singleEventPath}?e=${event?.eventId}`
    void navigator.clipboard?.writeText(permalink).then(() => setToastMessage('Link to event copied to clipboard.'))
    elementClicked(e, 'click-event-copy-link', {
      event_id: event?.eventId,
    })
  }

  const eventRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollToEvent === eventId && eventRef && !scrolled) {
      const y = (eventRef.current?.getBoundingClientRect()?.top ?? 0) - 60 // account for app header height
      document.body.children[0].scrollTo({ top: y, behavior: 'smooth' })
      setScrolled?.(true)
    }
  }, [scrollToEvent, eventRef, scrolled, setScrolled, eventId])

  const [editEvent] = useMutation(EditEventDocument)
  const [hideRepost] = useMutation(SetHideEventRepostDocument)
  const handleConfirmDelete = async (e: SyntheticEvent) => {
    setButtonsDisabled(true)
    if (event?.repost?.eventId) {
      await hideRepost({ variables: { eventId: event?.eventId || '', value: true, date: getStartOfCurrentDay() } })
    } else {
      await editEvent({
        variables: { hidden: true, eventId: event?.eventId || '', date: getStartOfCurrentDay(), notify: false },
      })
    }
    setButtonsDisabled(false)
    setToast?.('Event deleted')
    elementClicked(e, 'click-community-event-delete', { community_id: communityId, event_id: event?.eventId })
    setShowDeleteDialog(false)
  }

  const handleClose = () => {
    setIsEditing(false)
    onEditingChange?.(false, eventId)
  }

  const livestreamUrl = eventCommunity?.community?.livestreamPlayback
  const [startLivestream] = useMutation(StartLivestreamDocument)
  const [showStartLivestreamModal, setShowStartLivestreamModal] = useState(false)
  const [showEndLivestreamModal, setShowEndLivestreamModal] = useState(false)

  const toggleStartLivestream = async (start: boolean) => {
    await startLivestream({ variables: { eventId: eventId ?? '', start: start } })
    setShowStartLivestreamModal(false)
    setShowEndLivestreamModal(false)
    if (!start) turnOffLivestream?.()
  }

  const clickToggle = () => {
    if (event?.livestreamStart) {
      setShowEndLivestreamModal(true)
    } else {
      setShowStartLivestreamModal(true)
    }
  }

  return (
    <>
      {isEditing ? (
        <CommunityEventEdit communityId={communityId} onClose={handleClose} event={event} setToast={setToast} />
      ) : (
        <div className={`event-row ${event?.hidden ? 'deleted' : ''}`} ref={eventRef}>
          {!isLivestreamTab && <EventCalendarIconComponent eventStart={event?.eventStart} />}
          <div className="event-info">
            <div className={`event-details ${isLivestreamTab && 'wide-view'}`}>
              {isLivestreamTab && <EventCalendarIconComponent eventStart={event?.eventStart} />}
              <div>
                {canViewInfo && (
                  <div className="event-time">
                    {getEventTimeText(asDate(event?.eventStart), asDate(event?.eventEnd))}
                  </div>
                )}
                <div className="title">{event?.title}</div>
                {!loading && isVeevan && (event?.repost?.eventId || fromRepostPage) && (
                  <div className={'repost-message'}>
                    <div className={'repost-image'} />
                    <ProfilePhoto userId={repostUserId} />
                    <div>
                      <UserLink userId={repostUserId} />
                      reposted from the
                      <Link
                        to={`/communities/${repostCommunity?.community?.communityId}/events`}
                      >{` ${repostCommunity?.community?.name} `}</Link>
                      community <TimeAgo time={event?.createdTime} />
                    </div>
                  </div>
                )}
                {isVeevan && hasReposts && !fromRepostPage && (
                  <span className={'reposted-list'}>
                    <i>This event has been reposted in {repostedList}</i>
                  </span>
                )}
              </div>
            </div>
            {canViewInfo && (
              <>
                <div className="location">{formatLinks(event?.location ?? undefined)}</div>
                <div className="description quill-editor-elements">
                  {event?.isLivestream &&
                    !!livestreamUrl &&
                    (liveStreamInProgress ? (
                      joinLivestream ? (
                        <>
                          <button onClick={joinLivestream} className={'btn btn-primary join-livestream'}>
                            Join the Livestream
                          </button>
                        </>
                      ) : (
                        <VideoPlayer className="video" src={livestreamUrl} />
                      )
                    ) : (
                      <>
                        <span className={'notice'}>
                          {isFutureLivestream ? 'This live stream has not started yet.' : 'This live stream has ended.'}
                        </span>
                      </>
                    ))}
                  {/*Event descriptions don't include @ mentions but are still created from the quill editor. */}
                  {/*We want to treat links the same as we do in the post stories. */}
                  <MentionableText value={asHtmlWithMentions(asString(event?.description))} />
                </div>
              </>
            )}
            {!canViewInfo && (
              <div>
                <span className="join-community" onClick={handleJoinCommunity}>
                  {'Join this community'}
                </span>{' '}
                to see full event details.
              </div>
            )}
          </div>
          {!fromRepostPage && (
            <div className={`post-context-menu-area`}>
              <Dropdown align={'end'}>
                <Dropdown.Toggle as={KebabToggle} />
                <EventDropdownItems
                  eventId={''}
                  onDelete={handleClickDelete}
                  onClickEdit={handleClickEdit}
                  onClickRepost={handleClickRepost}
                  onClickCopy={handleClickCopy}
                  canEdit={!event?.repost?.eventId && canEdit}
                  canRepost={!event?.repost?.eventId && canRepost}
                  canDelete={canDelete || event?.createdById === authUserId}
                  isRepost={Boolean(event?.repost?.eventId)}
                  livestreamStarted={liveStreamInProgress}
                  canToggleLivestream={isFutureLivestream && actingSysAdmin}
                  toggleLivestream={clickToggle}
                />
              </Dropdown>
            </div>
          )}
          <Modal show={showDeleteDialog} onHide={handleCancelDelete} className={'delete'}>
            <Modal.Header closeButton />
            <Modal.Body>
              <p className={'message'}>
                Are you sure you want to delete this {event?.repost?.eventId ? 'repost' : 'event'}?
              </p>
              {event?.repost?.eventId && (
                <p className={'sub-message'}>
                  Deleting this repost will not affect the original event or other reposts
                </p>
              )}
              {hasReposts && (
                <p className={'notation warning'}>
                  Deleting this event will also delete {reposts?.length} event reposts.{' '}
                  <Link to={`/communities/${communityId}/events/${event?.eventId}/repost`}>View reposts</Link>
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" size="sm" onClick={handleCancelDelete}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={buttonsDisabled}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showStartLivestreamModal} onHide={() => setShowStartLivestreamModal(false)}>
            <Modal.Header closeButton />
            <Modal.Body>
              <p className={'message'}>Are you sure you want to start the live stream early?</p>
              <p className={'sub-message'}>
                Only Veevans will be able to see the live stream early. You can turn this back off until the event
                starts.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" size="sm" onClick={() => setShowStartLivestreamModal(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => toggleStartLivestream(true)}
                disabled={buttonsDisabled}
              >
                Go live early
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showEndLivestreamModal} onHide={() => setShowEndLivestreamModal(false)}>
            <Modal.Header closeButton />
            <Modal.Body>
              <p className={'message'}>Are you sure you want to turn off the early live stream?</p>
              <p className={'sub-message'}>The live stream will automatically begin again once the event starts.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" size="sm" onClick={() => setShowEndLivestreamModal(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => toggleStartLivestream(false)}
                disabled={buttonsDisabled}
              >
                Turn off
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <ToastComponent onClose={() => setToastMessage(null)} show={!!toastMessage}>
        {toastMessage ?? ''}
      </ToastComponent>
    </>
  )
}

export default CommunityEventRow
