import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { HomeFeedTab } from '~/pages/feed/HomeFeed'
import { useTitle } from '~/common/hooks/useTitle'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import {
  CommunitySortEnum,
  GetFollowingPostsDocument,
  GetFollowingPostsFullDocument,
  GetFollowingPostsQuery,
  GetFollowingPostsQueryVariables,
  GetMyCommunitiesDocument,
  GetMyCommunitiesPostsDocument,
  GetMyCommunitiesPostsFullDocument,
  GetMyCommunitiesPostsQuery,
  GetNewFeedPostActivityDocument,
  NewAllCommunityActivityAlertDocument,
  NewPublicCommunityActivityAlertDocument,
  SubscriptionInfo,
} from '~/api/generated/graphql'
import { useAuth, useSearchCacheContext } from '~/auth/Auth'
import { FeedActivityList } from '~/pages/feed/FeedActivityList'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'
import { useApolloClient, useLazyQuery, useQuery, useSubscription } from '@apollo/client'
import { PromptProvider } from '~/contexts/PromptContext'
import { DraftingCommentPostProvider } from '~/contexts/DraftingCommentPostContext'
import { MediaUploadProvider } from '~/contexts/MediaUploadContext'
import { useNavigate } from 'react-router'
import { usePageVisibility } from '~/contexts/PageVisibilityContext'
import { setFetchedActivity } from '~/utils'

const HomeFeedActivity = ({
  homeFeedTab,
  pageSize,
  refreshFeed,
  setRefreshFeed,
}: {
  homeFeedTab: HomeFeedTab
  pageSize?: number
  refreshFeed?: boolean
  setRefreshFeed?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { breakpoint } = useWindowSize()
  const { isVeevan, authUserId } = useAuth()
  const [postExpanded, setPostExpanded] = useState<boolean>(false)
  const { loadingContent } = useSearchCacheContext()
  useTitle('Feed - Veeva Connect')

  // these queries correspond to cache updates in PostUtils.ts/updateFeedPostFollow.
  // update in both places.
  const {
    previewData: postsData,
    loading: postsLoading,
    error,
    loadMore,
    hasMore,
    fullData: fullPostsData,
    refetch: refetchAll,
  } = useBackgroundFetch<GetMyCommunitiesPostsQuery, GetFollowingPostsQueryVariables>(
    GetMyCommunitiesPostsDocument,
    GetMyCommunitiesPostsFullDocument,
    {
      variables: { pageSize: pageSize ?? 25 },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )

  const {
    previewData: followingPostsQueryData,
    loading: followingPostsLoading,
    error: followingPostsError,
    loadMore: followingLoadMore,
    hasMore: followingHasMore,
    fullData: fullFollowingData,
    refetch: refetchFollowing,
  } = useBackgroundFetch<GetFollowingPostsQuery, GetFollowingPostsQueryVariables>(
    GetFollowingPostsDocument,
    GetFollowingPostsFullDocument,
    {
      variables: { pageSize: pageSize ?? 25 },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
    !postExpanded
  )
  const {
    loading: loadingPostUsers,
    processedPostIds,
    loadingFirstPage: loadingFirstPostUsers,
  } = useLoadObjUsers(postsData?.posts, 'postId')
  const {
    loading: loadingFollowingUsers,
    processedPostIds: processedFollowingPostIds,
    loadingFirstPage: loadingFirstFollowingUsers,
  } = useLoadObjUsers(followingPostsQueryData?.posts, 'postId')
  useLoadObjUsers(fullPostsData?.posts, 'postId')
  useLoadObjUsers(fullFollowingData?.posts, 'postId')

  // This is triggered in the recommended communities header in the home feed.
  useEffect(() => {
    setRefreshFeed?.(r => {
      if (r) {
        Promise.all([refetchAll(), refetchFollowing()]).then(
          () => {},
          () => {}
        )
      }
      return false
    })
  }, [refetchAll, refetchFollowing, refreshFeed, setRefreshFeed])

  const [newActivity, setNewActivity] = useState<SubscriptionInfo[]>([])

  const { data: userCommunitiesData } = useQuery(GetMyCommunitiesDocument, {
    variables: { communityFilter: {}, communitySort: [CommunitySortEnum.NameAsc] },
    fetchPolicy: 'cache-only',
  })

  const communityIds = useMemo(() => {
    return userCommunitiesData?.currentUser?.communities?.edges?.map(e => e?.node?.communityId ?? null) || []
  }, [userCommunitiesData])

  const { pageIsVisible } = usePageVisibility()

  const { data: postsOnVisibility } = useQuery(GetMyCommunitiesPostsDocument, {
    fetchPolicy: 'no-cache',
    variables: { pageSize: pageSize ?? 25 },
    skip: !pageIsVisible,
  })

  useEffect(() => {
    if (postsOnVisibility) {
      setFetchedActivity(postsOnVisibility, setNewActivity, postsData)
    }
  }, [postsData, postsOnVisibility])

  useSubscription(isVeevan ? NewAllCommunityActivityAlertDocument : NewPublicCommunityActivityAlertDocument, {
    variables: { communityIds: communityIds, authUserId: authUserId ?? '' },
    onData: ({ data }) => {
      if (data.data && data.data.newCommunityActivityAlert) {
        const p = data.data.newCommunityActivityAlert
        const activity = [...newActivity]
        if (!activity.some(a => a.objId === p.objId)) {
          activity.push(p)
        }
        setNewActivity(activity)
      }
    },
    skip: !pageIsVisible || globalThis.disableWebsocketServer,
  })

  const [getPostActivity] = useLazyQuery(GetNewFeedPostActivityDocument)
  const client = useApolloClient()
  const navigate = useNavigate()
  const showNewActivity = useCallback(async () => {
    const ids = newActivity.map(a => a.parentId || a.objId)
    const { data: postActivity } = await getPostActivity({ variables: { postIds: ids }, fetchPolicy: 'network-only' }) // update cache so post activity time is reflected correctly
    const oldData = client.readQuery({
      query: GetMyCommunitiesPostsDocument,
      variables: { pageSize: pageSize ?? 25 },
    })

    let newPosts = [...(oldData?.posts?.edges ?? [])]
    const nonDraftIndex = newPosts.findIndex(p => !p?.node?.draft)

    // remove any duplicates, which usually means we are reordering a post higher due to recent activity
    newPosts.splice(nonDraftIndex, 0, ...(postActivity?.posts?.edges ?? []))
    newPosts = newPosts.filter((p1, i, arr) => arr.findIndex(p2 => p1?.node?.postId === p2?.node?.postId) === i)

    const newPostsData = {
      ...oldData,
      posts: {
        ...oldData?.posts,
        pageInfo: {
          ...oldData?.posts?.pageInfo,
          hasNextPage: oldData?.posts?.pageInfo.hasNextPage ?? false,
        },
        edges: newPosts?.slice(0, pageSize ?? 25),
      },
    }

    client.writeQuery({
      query: GetMyCommunitiesPostsDocument,
      variables: { pageSize: pageSize ?? 25 },
      data: newPostsData,
    })
    setNewActivity?.([])
    navigate('/myfeed')
  }, [client, getPostActivity, navigate, newActivity, pageSize])

  const postIds = useMemo(() => {
    if (postsData && !postsLoading && !loadingPostUsers && Boolean(processedPostIds)) {
      return (
        (postsData.posts?.edges
          .map(edge => edge?.node?.postId)
          .filter(id => Boolean(id) && processedPostIds?.has(id ?? '')) as string[]) ?? []
      )
    }
  }, [loadingPostUsers, postsData, postsLoading, processedPostIds])

  const followingPostIds = useMemo(() => {
    if (followingPostsQueryData && !followingPostsLoading && !loadingFirstFollowingUsers) {
      return (
        (followingPostsQueryData.posts?.edges
          .map(edge => edge?.node?.postId)
          .filter(id => Boolean(id) && processedFollowingPostIds?.has(id ?? '')) as string[]) ?? []
      )
    }
  }, [followingPostsLoading, followingPostsQueryData, loadingFirstFollowingUsers, processedFollowingPostIds])

  const isCondensed = breakpoint <= SizeBreakpoint.lg

  return (
    <PromptProvider>
      <DraftingCommentPostProvider>
        <MediaUploadProvider>
          <div className={`recent-activity ${isCondensed ? 'condensed' : ''}`}>
            {homeFeedTab == HomeFeedTab.My ? (
              <FeedActivityList
                postIds={postIds}
                loading={(postsLoading && !postsData) || loadingContent || loadingFirstPostUsers}
                loadingMore={postsLoading || loadingPostUsers}
                loadMore={loadMore}
                hasMore={hasMore}
                error={error}
                feedOption={homeFeedTab}
                setExpanded={setPostExpanded}
                newActivity={newActivity}
                showNewActivity={showNewActivity}
              />
            ) : (
              <FeedActivityList
                postIds={followingPostIds}
                loading={
                  (followingPostsLoading && !followingPostsQueryData) || loadingContent || loadingFirstFollowingUsers
                }
                loadingMore={followingPostsLoading || loadingFollowingUsers}
                loadMore={followingLoadMore}
                hasMore={followingHasMore}
                error={followingPostsError}
                feedOption={homeFeedTab}
                setExpanded={setPostExpanded}
                newActivity={newActivity}
                showNewActivity={showNewActivity}
              />
            )}
          </div>
        </MediaUploadProvider>
      </DraftingCommentPostProvider>
    </PromptProvider>
  )
}

export default HomeFeedActivity
