import React, { SyntheticEvent } from 'react'
import { CommunityModel } from '~/types'
import '@css/pages/profile/ProfileContent.scss'
import { Link } from 'react-router-dom'
import JoinCommunityButton, { MembershipAction } from '~/common/JoinCommunityButton'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import MobileCommunityListItem from '~/pages/community/MobileCommunityListItem'
import PrivateCommunityIcon from '@web/images/community/PrivateCommunityIcon'
import { elementClicked } from '~/common/EventLogger'
import { useProfile } from '~/contexts/ProfileContext'

type Props = {
  community: CommunityModel
  setUpdated: () => void
}

export const ProfileCommunityRow = ({ community, setUpdated }: Props) => {
  const { isCondensed } = useWindowSize()
  const { userId } = useProfile()
  const communityLink = `/communities/${community.communityId}/about`
  const isPrivate = community.type === 'Private'
  const { searchMine, searchAll } = useCommunities()

  const logJoinClicked = (action: MembershipAction, e: SyntheticEvent) => {
    elementClicked(
      e,
      action === MembershipAction.join ? 'click-profile-community-join' : 'click-profile-community-leave',
      { communityId: community.communityId, userId: userId }
    )
  }
  const refetchCommunities = () => {
    searchMine('')
    searchAll('')
    setUpdated()
  }

  const standardLayout = (
    <div className="community-row">
      <div>
        <div className="community-photo">
          <Link to={communityLink} tabIndex={-1}>
            <div className="community-image" style={{ backgroundImage: `url(${community.photo ?? ''})` }} />
          </Link>
        </div>
        <div className="community-name">
          <div className="community-private">
            <h2>
              <Link to={communityLink}>{community.name}</Link>
              {isPrivate && <PrivateCommunityIcon />}
            </h2>
          </div>
          <h3>{community.description}</h3>
        </div>
      </div>
      <div>
        <div role="join-button">
          <JoinCommunityButton
            communityId={community.communityId}
            communityName={community.name}
            logJoinClicked={logJoinClicked}
            onActionCompleted={refetchCommunities}
            fillFullWidth={true}
          />
        </div>
      </div>
    </div>
  )

  if (isCondensed)
    return (
      <div className="mobile-row">
        <MobileCommunityListItem community={community} showPrivateCommIcon={true} />
      </div>
    )
  else return standardLayout
}
