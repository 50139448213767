import { UploadZoneInline } from '~/pages/posts/AddMediaTarget'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react'
import { HtmlWithMentions, Maybe, MediaAlignmentType, MediaType, MeetupInput } from '~/api/generated/graphql'
import { Button } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import { EditorRowData } from '~/common/quill/MultipleEditor'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import DragDropIcon from '@web/images/posts/DragDrop.svg'
import MentionableText from '~/common/MentionableText'

type EditorRowProps = {
  onInputChange: (value: Maybe<HtmlWithMentions>) => void
  onMediaChange: (
    index: number,
    type?: MediaType,
    uploadId?: string,
    filename?: string,
    videoUrl?: string,
    mediaUrl?: string
  ) => void
  setMediaType: (index: number, mediaType?: MediaType) => void
  setMediaName: (index: number, mediaName?: string) => void
  setDirectUrlUpload: (index: number, vid: string | null) => void
  row: EditorRowData
  onDelete: () => void
  onDeleteMedia: (index: number, removeTarget: boolean) => void
  onFocus: () => void
  focused: boolean
  onLeftMediaClick: () => void
  onRightMediaClick: () => void
  onFullMediaClick: () => void
  onAddPastedImage: (file: File, index: number) => void
  forceHideDelete: boolean
  placeholder?: string
  communityId: string
  postId?: string
  commentId?: string
  veevanOnlyMentions?: boolean
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableProps?: Maybe<DraggableProvidedDraggableProps>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draggableInnerRef?: (element?: Maybe<HTMLElement>) => any
  draggingRow?: boolean // dragging this specific row
  dragging?: boolean // dragging any row
  totalRows?: number
  isCondensed?: boolean
  insertPill?: JSX.Element
  index: number
  setFocusedIndex: React.Dispatch<React.SetStateAction<number>>
  noToolbar?: boolean
  onSubmit?: (args: {
    skipWarning?: boolean
    warned?: boolean
    e?: SyntheticEvent | KeyboardEvent
    meetup?: MeetupInput
  }) => Promise<void>
  hasMeetup?: boolean
  updateQuillRef?: (ref: ReactQuill) => void
  setMeetup?: (meetup: MeetupInput) => void
  primaryVideoUrl?: Maybe<string>
  allowVideoTimestamps?: boolean
}

const EditorRow = ({
  onInputChange,
  onMediaChange,
  setMediaType,
  setMediaName,
  setDirectUrlUpload,
  onDeleteMedia,
  onDelete,
  onFocus,
  row,
  focused,
  onLeftMediaClick,
  onRightMediaClick,
  onFullMediaClick,
  onAddPastedImage,
  placeholder,
  forceHideDelete,
  communityId,
  postId,
  commentId,
  veevanOnlyMentions,
  dragHandleProps,
  draggableInnerRef,
  draggableProps,
  draggingRow,
  dragging,
  totalRows,
  isCondensed,
  insertPill,
  index,
  setFocusedIndex,
  noToolbar,
  onSubmit,
  hasMeetup,
  updateQuillRef,
  setMeetup,
  primaryVideoUrl,
  allowVideoTimestamps,
}: EditorRowProps) => {
  const { quillRef: propsRef, story, mediaUrl, mediaType, alignment, file, filename, forceSetFocus } = row

  const localRef = useRef<ReactQuill | null>(null)
  const quillRef = propsRef?.current ? propsRef : localRef

  const hideToolbar = alignment === MediaAlignmentType.LeftMedia || alignment === MediaAlignmentType.RightMedia
  const multipleRows = totalRows ? totalRows > 1 : false

  useEffect(() => {
    // to make the experience a little less annoying, do not autofocus the first row if it is only text
    // unless we explicitly force it to focus (which is only relevant for one specific case for comments)
    if (forceSetFocus || !forceHideDelete) {
      quillRef.current?.focus()
    }
  }, [forceHideDelete, forceSetFocus, quillRef])

  // Ensure cursor is set to end of text when switching between editor rows
  useEffect(() => {
    if (focused && multipleRows) {
      const textEnd = quillRef.current?.editor?.getLength() ?? 0
      quillRef.current?.setEditorSelection(quillRef.current?.getEditor(), { index: textEnd, length: 0 })
    }
  }, [focused, multipleRows, quillRef])

  // When re-ordering rows, we set which row should be focused at the new index.
  // The row that was previously at that index needs to be blurred once re-ordering starts, otherwise we see a 'flash'
  // where there is a focus around the wrong row
  useEffect(() => {
    if (dragging) {
      quillRef.current?.blur()
    }
  }, [quillRef, dragging])

  const uploadZone = useMemo(
    () => (
      // since this component re-renders every time anything related to any story row changes, this means
      // media will also re-render every time anything related to any story row changes. If there is a big
      // image file that was uploaded, this constant re-rendering can be problematic. Therefore, we memoize
      // the upload zone so that it only re-renders if a prop changes
      <UploadZoneInline
        setUploadId={(type, uploadId, filename, mediaUrl) =>
          onMediaChange(index, type, uploadId, filename, undefined, mediaUrl)
        }
        setVideoUrl={(videoUrl, mediaType) => onMediaChange(index, mediaType, undefined, undefined, videoUrl, videoUrl)}
        setDirectUrlUpload={vid => setDirectUrlUpload(index, vid)}
        directUrlUpload={row.directUrlUpload ?? null}
        onClear={() => onDeleteMedia(index, false)}
        onDelete={() => onDeleteMedia(index, true)}
        showDelete={focused}
        mediaUrl={mediaUrl}
        setMediaType={type => setMediaType(index, type)}
        setMediaName={name => setMediaName(index, name)}
        mediaName={filename}
        mediaType={mediaType}
        file={file}
        post_id={postId}
        comment_id={commentId}
      />
    ),
    [
      row.directUrlUpload,
      focused,
      mediaUrl,
      filename,
      mediaType,
      file,
      postId,
      onMediaChange,
      index,
      setDirectUrlUpload,
      onDeleteMedia,
      setMediaType,
      setMediaName,
      commentId,
    ]
  )

  return (
    <>
      <div
        className={`editor-row
        ${draggingRow ? ' dragging' : ''}
        ${focused && !noToolbar ? ' focused' : ''}
        `}
        ref={draggableInnerRef}
        {...draggableProps}
      >
        <div
          className={`editor-row-inner-wrapper
          ${alignment === MediaAlignmentType.RightMedia ? ' right-media' : ''}${
            alignment === MediaAlignmentType.FullText ? ' full-text' : ''
          }${hideToolbar ? ' hide-toolbar' : ''}
          ${!multipleRows && alignment === MediaAlignmentType.FullText ? ' original-edit-view' : ''}
          ${isCondensed ? ' condensed' : ''}
          `}
          onClick={() => setFocusedIndex(index)}
        >
          {alignment !== MediaAlignmentType.RightMedia && (
            <div
              className={`handle-container ${!focused || !draggingRow || noToolbar ? 'hide-handle' : ''} 
              ${multipleRows ? '' : 'remove-handle'} ${isCondensed ? 'condensed' : ''}`}
            >
              <img src={DragDropIcon} {...dragHandleProps} data-testid={`dnd-handle`} alt={'drag-drop-handle'} />
            </div>
          )}
          {alignment !== MediaAlignmentType.FullText && (
            <div className={`row-media-container${alignment === MediaAlignmentType.FullMedia ? ' full' : ''}`}>
              {uploadZone}
            </div>
          )}
          {alignment !== MediaAlignmentType.FullMedia && (
            <div className={`row-quill-container${alignment === MediaAlignmentType.FullText ? ' full' : ''}`}>
              {focused ? (
                <QuillEditor<Maybe<HtmlWithMentions>>
                  toolbar={QuillToolbar.PostStory}
                  onFocus={onFocus}
                  setHtml={onInputChange}
                  initialHtml={story}
                  allowMentions={true}
                  className={`${focused && !noToolbar ? '' : 'hide-toolbar limit-height'} ${
                    isCondensed || (hideToolbar && focused) ? 'condensed' : ''
                  } ${alignment === MediaAlignmentType.FullText ? '' : 'left-right-aligned'}`}
                  placeholder={placeholder}
                  quillRef={quillRef}
                  addPastedImage={onAddPastedImage}
                  communityId={communityId ?? ''}
                  postId={postId ?? ''}
                  veevanOnlyMentions={veevanOnlyMentions}
                  rowIndex={index}
                  onFullMediaClick={onFullMediaClick}
                  onLeftMediaClick={onLeftMediaClick}
                  onRightMediaClick={onRightMediaClick}
                  onSubmit={onSubmit}
                  hasMeetup={hasMeetup}
                  updateQuillRef={updateQuillRef}
                  setMeetup={setMeetup}
                  primaryVideoUrl={primaryVideoUrl}
                  allowVideoTimestamps={allowVideoTimestamps}
                />
              ) : (
                <div
                  className={`quill editor-placeholder ${
                    alignment === MediaAlignmentType.FullText ? '' : 'left-right-aligned'
                  }`}
                >
                  <div className={'ql-container ql-snow'}>
                    <div
                      className={`ql-editor${alignment === MediaAlignmentType.RightMedia ? ' right-media' : ''}${
                        alignment === MediaAlignmentType.FullText ? ' full-text' : ''
                      }`}
                    >
                      <div className={`row-quill-container${alignment === MediaAlignmentType.FullText ? ' full' : ''}`}>
                        <MentionableText value={story} postId={postId} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {focused && !forceHideDelete && <Button className={'media-remove'} onClick={onDelete} />}
            </div>
          )}
          {alignment === MediaAlignmentType.RightMedia && (
            <div
              className={`handle-container ${!focused || !draggingRow ? 'hide-handle' : ''} ${
                dragging && !draggingRow ? 'hide-handle-hover' : ''
              } ${multipleRows ? '' : 'remove-handle'} ${isCondensed ? 'condensed' : ''}`}
            >
              <img src={DragDropIcon} {...dragHandleProps} />
            </div>
          )}
        </div>
        {insertPill}
      </div>
    </>
  )
}

export default EditorRow
