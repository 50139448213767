import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import headerFeed from '@web/images/header/header-feed.svg'
import headerCommunities from '@web/images/header/header-communities.svg'
import headerSummit from '@web/images/header/header-summit.svg'

import headerRelease from '@web/images/header/header-releases.svg'
import '@css/common/AppHeader.scss'
import '@css/common/BannerComponent.scss'
import { Link } from 'react-router-dom'
import { useAuth } from '~/auth/Auth'
import { useOutsideAlerter } from '~/common/hooks/useOutsideAlerter'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import {
  EditUserDocument,
  GetReleasesDropdownDocument,
  GetSummitsDropdownDocument,
  SearchUsersDocument,
  SetAdminRoleDocument,
} from '~/api/generated/graphql'
import { debounce, getMaxTypeaheadSlots } from '~/utils'
import HamburgerIcon from '@web/images/header/header-hamburger.svg'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import TypeaheadSearch, { SearchObject, SearchObjectType } from '~/common/TypeaheadSearch'
import SearchIcon from '@web/images/community/icon-search.svg'
import { Button } from 'react-bootstrap'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { elementClicked, searchResultsLogger } from '~/common/EventLogger'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { useCommunity } from '~/contexts/CommunityContext'
import ReleasesDropdownItems from '~/common/ReleasesDropdownItems'
import { getInstantSearchOptions } from '~/pages/search/SearchUtilities'
import { useShortcuts } from '~/contexts/ShortcutContext'
import SummitsDropdownItems from '~/common/SummitDropdownItems'
import NotificationList from '~/common/NotificationList'
import { LocalStorageKey } from '~/types'

declare global {
  // eslint-disable-next-line no-var
  var prefixCache: Map<string, Set<string>> | undefined
}

export const AppHeader = ({ currentPage }: { currentPage?: string }) => {
  const { breakpoint } = useWindowSize()
  const { communityId } = useCommunity()
  const {
    profileVisible,
    authUserId,
    sysAdminMember,
    actingSysAdmin,
    relAdminMember,
    summitAdminMember,
    inAdminRole,
    isVeevan,
    signOut,
  } = useAuth()

  const isAnyAdminEligible = sysAdminMember || relAdminMember || summitAdminMember
  const [setAdminRole] = useMutation(SetAdminRoleDocument)

  useQuery(GetReleasesDropdownDocument)
  useQuery(GetSummitsDropdownDocument)

  const { cache } = useApolloClient()

  const [editUser] = useMutation(EditUserDocument)
  const condensed = breakpoint <= SizeBreakpoint.lg

  const unregisterUser = () => {
    void editUser({
      variables: {
        user_id: authUserId ?? '',
        profile_visible: true,
      },
    })
  }
  const profileRef = useRef(null)
  const profileIconRef = useClickOnEnter<HTMLImageElement>()
  const hamburgerRef = useRef(null)
  const hamburgerIconRef = useRef<HTMLImageElement>(null)
  const summitRef = useRef(null)
  const summitIconRef = useRef<HTMLImageElement>(null)
  const summitHamburgerRef = useRef(null)
  const summitHamburgerDivRef = useRef<HTMLDivElement>(null)
  const releaseIconRef = useRef<HTMLImageElement>(null)
  const releasesDropdownRef = useRef<HTMLDivElement>(null)
  const [options, setOptions] = useState<SearchObject[]>([])
  const [showOnlySearch, setShowOnlySearch] = useState<boolean>(false)
  const { focusSearch, setFocusSearch } = useShortcuts()
  const [searchTerm, setSearchTerm] = useState<string>()

  const { data: searchedUsers } = useQuery(SearchUsersDocument, {
    variables: {
      query: searchTerm ?? '',
      pageSize: getMaxTypeaheadSlots(isVeevan),
    },
    skip: !searchTerm,
  })

  const delayedUserMatches: SearchObject[] = useMemo(() => {
    return (
      searchedUsers?.userSearch?.users.map(u => {
        const title = `${u?.firstName} ${u?.lastName}` + (u?.nickName ? ` (${u.nickName})` : '')
        return {
          title,
          type: SearchObjectType.user,
          link: `/profiles/${u?.userId}`,
          photo: u?.photo,
          subtitle: `${u?.title}, ${u?.company?.name}`,
          sortKey: `${u?.lastName} ${u?.firstName}`,
          idKey: `User:${u?.userId}`,
        }
      }) ?? []
    )
  }, [searchedUsers])

  useEffect(() => {
    if (focusSearch && condensed) {
      setShowOnlySearch(true)
    }
  }, [condensed, focusSearch])

  useEffect(() => {
    setOptions(options => {
      const skipIds = new Set(options.filter(o => o.type == SearchObjectType.user).map(o => o.idKey))
      const dedupedUsers = delayedUserMatches.filter(u => !skipIds.has(u?.idKey ?? ''))
      if (dedupedUsers.length > 0) {
        return options.concat(dedupedUsers)
      }
      return options
    })
  }, [delayedUserMatches])

  const debouncedSearchLogger = useMemo(() => debounce(searchResultsLogger, 300), [])

  const handleSearch = (globalSearch: string) => {
    const normalizedSearch = globalSearch.normalize('NFKD')
    const recentSearches = JSON.parse(localStorage.getItem(LocalStorageKey.recentSearchSelection) ?? '[]') as string[]
    const searchOptions = getInstantSearchOptions(cache, normalizedSearch, actingSysAdmin, recentSearches)
    const fullSearchOptions = searchOptions
      .concat([
        { title: 'all', type: SearchObjectType.advancedLink, link: 'all', sortKey: 'all', idKey: '' },
        { title: 'my', type: SearchObjectType.advancedLink, link: 'my', sortKey: 'my', idKey: '' },
        {
          title: 'custom',
          type: SearchObjectType.advancedLink,
          link: communityId ?? 'custom',
          sortKey: 'custom',
          idKey: '',
        },
      ])
      .filter(o => o.title !== '')
    setOptions(fullSearchOptions)
    setSearchTerm(normalizedSearch)

    if (globalSearch) {
      void debouncedSearchLogger(globalSearch, searchOptions)
    }
  }

  const { showElement: showDropdownOptions, setShowElement: setShowDropdownOptions } = useOutsideAlerter(
    false,
    profileRef,
    profileIconRef
  )

  const { showElement: showHamburgerMenu, setShowElement: setShowHamburgerMenu } = useOutsideAlerter(
    false,
    hamburgerRef,
    hamburgerIconRef
  )

  const { showElement: showSummitDropdownOptions, setShowElement: setShowSummitDropdownOptions } = useOutsideAlerter(
    false,
    summitRef,
    summitIconRef
  )

  const { showElement: showReleasesDropdownOptions, setShowElement: setShowReleasesDropdownOptions } =
    useOutsideAlerter(false, releasesDropdownRef, releaseIconRef)

  const { showElement: showSummitHamburgerDropdownOptions, setShowElement: setShowSummitHamburgerDropdownOptions } =
    useOutsideAlerter(false, summitHamburgerRef, summitHamburgerDivRef)

  const [showReleasesHamburgerDropdownOptions, setShowReleasesHamburgerDropdownOptions] = useState<boolean>(false)
  const notifRef = useRef(null)
  const notifIconRef = useClickOnEnter<HTMLImageElement>()
  const { showElement: showNotifications, setShowElement: setShowNotifications } = useOutsideAlerter(
    false,
    notifRef,
    notifIconRef
  )

  const handleDropdownClicked = useCallback(() => {
    setShowDropdownOptions(!showDropdownOptions)
    setShowReleasesDropdownOptions(false)
    setShowSummitDropdownOptions(false)
    setShowNotifications(false)
  }, [
    setShowDropdownOptions,
    showDropdownOptions,
    setShowReleasesDropdownOptions,
    setShowSummitDropdownOptions,
    setShowNotifications,
  ])

  const handleSummitDropdownClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-summits')
      setShowSummitDropdownOptions(!showSummitDropdownOptions)
    },
    [showSummitDropdownOptions, setShowSummitDropdownOptions]
  )

  const handleReleaseDropdownClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-releases')
      setShowReleasesDropdownOptions(!showReleasesDropdownOptions)
    },
    [showReleasesDropdownOptions, setShowReleasesDropdownOptions]
  )

  const handleSummitDropdownEnterPressed = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        elementClicked(e, 'click-topnav-summits')
        setShowSummitDropdownOptions(!showSummitDropdownOptions)
        setShowReleasesDropdownOptions(false)
        setShowNotifications(false)
        setShowDropdownOptions(false)
      }
    },
    [
      setShowSummitDropdownOptions,
      showSummitDropdownOptions,
      setShowReleasesDropdownOptions,
      setShowNotifications,
      setShowDropdownOptions,
    ]
  )

  const handleReleaseDropdownEnterPressed = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        elementClicked(e, 'click-topnav-releases')
        setShowReleasesDropdownOptions(!showReleasesDropdownOptions)
        setShowNotifications(false)
        setShowSummitDropdownOptions(false)
        setShowDropdownOptions(false)
      }
    },
    [
      setShowReleasesDropdownOptions,
      showReleasesDropdownOptions,
      setShowNotifications,
      setShowSummitDropdownOptions,
      setShowDropdownOptions,
    ]
  )

  const handleHamburgerClicked = useCallback(() => {
    setShowHamburgerMenu(!showHamburgerMenu)
    // Close nested menus
    setShowSummitHamburgerDropdownOptions(false)
    setShowReleasesHamburgerDropdownOptions(false)
  }, [showHamburgerMenu, setShowHamburgerMenu, setShowSummitHamburgerDropdownOptions])

  const handleSummitHamburgerDropdownClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-summits')
      e.stopPropagation()
      setShowSummitHamburgerDropdownOptions(!showSummitHamburgerDropdownOptions)
    },
    [showSummitHamburgerDropdownOptions, setShowSummitHamburgerDropdownOptions]
  )

  const handleReleasesHamburgerDropdownClicked = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-releases')
      e.stopPropagation()
      setShowReleasesHamburgerDropdownOptions(!showReleasesHamburgerDropdownOptions)
    },
    [showReleasesHamburgerDropdownOptions, setShowReleasesHamburgerDropdownOptions]
  )

  const handleSignOut = useCallback(
    (e: React.MouseEvent) => {
      elementClicked(e, 'click-topnav-logout')
      signOut(() => (location.href = '/login'), e)
    },
    [signOut]
  )

  const toggleAdminMode = async () => {
    const resp = await setAdminRole({ variables: { adminRole: !inAdminRole } })
    if (resp?.data?.setAdminRole?.ok) {
      localStorage.setItem(LocalStorageKey.recentSearchSelection, JSON.stringify([]))
      globalThis.location.reload()
    }
  }

  const namespace = JSON.parse(document.getElementById('data/NS')?.textContent ?? '""')
  const isHomeFeed = currentPage === 'home'

  return (
    <>
      <div
        className={`header-zone-container ${condensed ? 'condensed' : ''} ${namespace == 'prod' ? '' : 'blue-banner'}`}
      >
        <div className={`header-zone${condensed ? ' condensed' : ''}`} id={'AppHeader'}>
          {showOnlySearch ? (
            <div className={'only-search'}>
              <TypeaheadSearch options={options} handleSearch={handleSearch} fullHeaderView={true} />
              <button
                onClick={() => {
                  setShowOnlySearch(false)
                  setFocusSearch?.(false)
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <>
              <div className={'left-most-items'}>
                {condensed && (
                  <img
                    className={'hamburger-icon'}
                    alt="menu"
                    src={HamburgerIcon}
                    role={'button'}
                    ref={hamburgerIconRef}
                    onClick={handleHamburgerClicked}
                    data-testid={'hamburger-icon'}
                  />
                )}
                <Link
                  to={'/'}
                  className={`veeva-connect-logo`}
                  reloadDocument={isHomeFeed}
                  onClick={e => elementClicked(e, 'click-topnav-logo')}
                />
                {namespace != 'prod' && <div className={`namespace`}>{namespace}</div>}
              </div>
              {!condensed && <TypeaheadSearch options={options} handleSearch={handleSearch} fullHeaderView={false} />}
              <div className={'right-side-items'}>
                {condensed && (
                  <img
                    alt="menu"
                    className={'search-icon'}
                    src={SearchIcon}
                    onClick={() => setShowOnlySearch(true)}
                    role={'button'}
                    data-testid={'search-icon'}
                  />
                )}
                {!condensed && (
                  <>
                    <Link
                      to={'/'}
                      className={isHomeFeed ? 'selected' : ''}
                      onClick={e => elementClicked(e, 'click-topnav-feed')}
                    >
                      <img src={headerFeed} alt={'Feed'} />
                      <span>Feed</span>
                    </Link>
                    <Link
                      to={'/communities'}
                      className={currentPage === 'comm' ? 'selected' : ''}
                      onClick={e => elementClicked(e, 'click-topnav-communities')}
                    >
                      <img src={headerCommunities} alt={'Communities'} />
                      <span>Communities</span>
                    </Link>
                    <div className="header-dropdown-container">
                      <div
                        className={`summit-dropdown${currentPage === 'summits' ? ' selected' : ''} ${
                          showSummitDropdownOptions ? ' dropdown-open' : ''
                        }`}
                        onClick={handleSummitDropdownClicked}
                        onKeyDown={handleSummitDropdownEnterPressed}
                        role="button"
                        aria-label="Summit"
                        tabIndex={0}
                        ref={summitIconRef}
                      >
                        <img src={headerSummit} alt={'Summit'} />
                        <span>Summits</span>
                      </div>
                      {showSummitDropdownOptions && (
                        <div
                          className="dropdown-menu header-dropdown-menu release-dropdown-menu"
                          aria-label="Summit Menu"
                          ref={summitRef}
                        >
                          <SummitsDropdownItems hide={() => setShowSummitDropdownOptions(false)} />
                        </div>
                      )}
                    </div>
                    <div className={'header-dropdown-container'}>
                      <div
                        className={`summit-dropdown${currentPage === 'releases' ? ' selected' : ''} ${
                          showReleasesDropdownOptions ? ' dropdown-open' : ''
                        }`}
                        onClick={handleReleaseDropdownClicked}
                        onKeyDown={handleReleaseDropdownEnterPressed}
                        tabIndex={0}
                        ref={releaseIconRef}
                      >
                        <img src={headerRelease} alt={'Release'} />
                        <span>Releases</span>
                      </div>
                      {showReleasesDropdownOptions && (
                        <div
                          className="dropdown-menu header-dropdown-menu release-dropdown-menu"
                          aria-label="Release Menu"
                          ref={releasesDropdownRef}
                        >
                          <ReleasesDropdownItems hide={() => setShowReleasesDropdownOptions(false)} />
                        </div>
                      )}
                    </div>
                  </>
                )}
                <NotificationList
                  setShowReleasesDropdownOptions={setShowReleasesDropdownOptions}
                  setShowSummitDropdownOptions={setShowSummitDropdownOptions}
                  setShowDropdownOptions={setShowDropdownOptions}
                  setShowNotifications={setShowNotifications}
                  showNotifications={showNotifications}
                  notifRef={notifRef}
                  notifIconRef={notifIconRef}
                />
                <div
                  className={`header-dropdown${condensed ? ' condensed' : ''}`}
                  onClick={handleDropdownClicked}
                  role="button"
                  aria-label="Profile"
                  ref={profileIconRef}
                  data-testid={'profile-photo-icon'}
                  tabIndex={0}
                >
                  <ProfilePhoto userId={authUserId} noBadge={true} adminBadge={inAdminRole} />
                </div>
                {showDropdownOptions && (
                  <div
                    className="dropdown-menu header-dropdown-menu"
                    aria-label="User Menu"
                    onClick={handleDropdownClicked}
                    ref={profileRef}
                    onSelect={() => false}
                  >
                    <Link
                      to={`/profiles/${authUserId}`}
                      className={'dropdown-item'}
                      onClick={e => elementClicked(e, 'click-topnav-profile')}
                      aria-label="Profile"
                    >
                      My Profile
                    </Link>
                    <Link
                      to={`/settings`}
                      className={'dropdown-item'}
                      onClick={e => elementClicked(e, 'click-topnav-settings')}
                      aria-label="My Settings"
                    >
                      My Settings
                    </Link>
                    {isAnyAdminEligible && (
                      <>
                        <div role={'separator'} className={'divider'} />
                        <div role={'button'} className={'dropdown-item'} aria-label="Admin" onClick={toggleAdminMode}>
                          {inAdminRole ? 'Disable' : 'Enable'} Admin Mode
                        </div>
                        {actingSysAdmin && (
                          <>
                            <Link to={'/admin'} className={'dropdown-item'} aria-label="Admin">
                              Admin
                            </Link>
                            <Link to={'/releasesmenu'} className={'dropdown-item'} aria-label={'Releases Menu'}>
                              Releases Menu
                            </Link>
                            <Link to={'/addreleasepage'} className={'dropdown-item'} aria-label="Add Release Page">
                              Add Release Page
                            </Link>
                            <Link to={'/summitsmenu'} className={'dropdown-item'} aria-label={'Summits Menu'}>
                              Summits Menu
                            </Link>
                            <Link to={'/addsummitpage'} className={'dropdown-item'} aria-label="Add Summit Page">
                              Add Summit Page
                            </Link>
                          </>
                        )}
                      </>
                    )}
                    {isVeevan && (
                      <>
                        <div role={'separator'} className={'divider'} />
                        <Link to={'/addcustomer'} className={'dropdown-item'} aria-label="Add Customer">
                          Add Customer
                        </Link>
                        <div role={'separator'} className={'divider'} />
                      </>
                    )}
                    <a
                      onClick={handleSignOut}
                      className={'dropdown-item logout-button'}
                      aria-label="Logout"
                      // this needs a tab index
                      tabIndex={0}
                    >
                      Logout
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
          {showHamburgerMenu && (
            <div className="dropdown-menu hamburger-dropdown-menu" ref={hamburgerRef} onClick={handleHamburgerClicked}>
              <Link to={'/'} className={'dropdown-item'} onClick={e => elementClicked(e, 'click-topnav-feed')}>
                Feed
              </Link>
              <Link
                to={'/communities'}
                className={'dropdown-item'}
                onClick={e => elementClicked(e, 'click-topnav-communities')}
              >
                Communities
              </Link>
              <div
                className="dropdown-item expandable"
                onClick={handleSummitHamburgerDropdownClicked}
                role="button"
                aria-label="Summit"
                ref={summitHamburgerDivRef}
              >
                <span className={showSummitHamburgerDropdownOptions ? 'expanded' : ''}>Summits &#9662;</span>
              </div>
              {showSummitHamburgerDropdownOptions && (
                <div className={'summits-menu-items'}>
                  <SummitsDropdownItems hide={() => setShowSummitHamburgerDropdownOptions(false)} />
                </div>
              )}
              <div
                className="dropdown-item expandable"
                onClick={handleReleasesHamburgerDropdownClicked}
                role="button"
                aria-label="Page"
              >
                <span className={showReleasesHamburgerDropdownOptions ? 'expanded' : ''}>Releases &#9662;</span>
              </div>
              {showReleasesHamburgerDropdownOptions && (
                <div className={'releases-menu-items'}>
                  <ReleasesDropdownItems hide={() => setShowHamburgerMenu(false)} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!profileVisible && (
        <div
          className={`banner-content ${currentPage == 'profile' ? 'profile-page' : ''} ${condensed ? 'condensed' : ''}`}
        >
          Your account is currently set to Read Only. Your profile is not visible.&nbsp;
          <Button variant={'link'} onClick={unregisterUser} className="banner-bold">
            Enable your profile to join and participate in Communities
          </Button>
        </div>
      )}
    </>
  )
}
