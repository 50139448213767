import React, { Dispatch, JSX, SetStateAction } from 'react'
import {
  Community,
  CommunityType,
  Company,
  CreateMediaMutation,
  CreateMediaMutationVariables,
  Event,
  EventPartsFragment,
  Exact,
  GetCommunitiesByNameQuery,
  GetCommunitiesByNameQueryVariables,
  GetMyCommunitiesPostsFullQuery,
  GetMyCommunitiesPostsFullQueryVariables,
  GetMyCommunitiesPostsQuery,
  GetMyCommunitiesQuery,
  GetNewPostActivityQuery,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  GetPermissionsDocument,
  GetPermissionsQuery,
  GetPostsDocument,
  GetPostsQuery,
  GetSuggestedCommunitiesQuery,
  GetSuggestedCommunitiesQueryVariables,
  Html,
  HtmlWithMentions,
  InputMaybe,
  Maybe,
  MediaType,
  Membership,
  MembershipPartsFragment,
  MembershipPartsFragmentDoc,
  NotificationEmailFrequency,
  NotificationInfo,
  PostPartsFragment,
  PostType,
  RemoveMemberMutation,
  Role,
  SubscriptionInfo,
  User,
} from '~/api/generated/graphql'
import {
  CommentModel,
  CommunityListViewModel,
  CommunityModel,
  CompanyModel,
  EventModel,
  LocationModel,
  NotificationExtraModel,
  NotificationModel,
  PostModel,
  RoleModel,
  UserModel,
} from '~/types'
import {
  ApolloCache,
  ApolloClient,
  DocumentNode,
  FetchResult,
  InMemoryCache,
  LazyQueryExecFunction,
  MutationFunctionOptions,
  QueryResult,
  Reference,
} from '@apollo/client'
import image_icon from '@web/images/file-icons/IMG.svg'
import doc_icon from '@web/images/file-icons/docGoogle.svg'
import spreadsheet_icon from '@web/images/file-icons/xlsExcel.svg'
import presentation_icon from '@web/images/file-icons/pptPowerpoint.svg'
import pdf_icon from '@web/images/file-icons/PDF.svg'
import video_icon from '@web/images/file-icons/Video.svg'
import vpk_icon from '@web/images/file-icons/VPK.svg'
import zip_icon from '@web/images/file-icons/ZIP.svg'
import generic_icon from '@web/images/file-icons/FileGeneric.svg'
import { Suggestion } from '~/common/quill/QuillEditor'
import { FieldNode, OperationDefinitionNode } from 'graphql/language'
import { getLinkedinPhoto } from '~/api/ServerApi'
import { URLSearchParamsInit } from 'react-router-dom'
import normalizeUrl from 'normalize-url'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'

export const getFullName = (
  user?: Maybe<{
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    nickName?: Maybe<string>
  }>
): string => {
  if (!user) return ''
  const { firstName, lastName, nickName } = user
  return [firstName, lastName, nickName ? `(${nickName})` : null].filter(Boolean).join(' ')
}

export const getNameOrEmail = ({
  firstName,
  lastName,
  email,
}: {
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  email?: Maybe<string>
}) => {
  return firstName && lastName ? `${firstName} ${lastName}` : email
}

export const getSimpleName = (fName: string, lName: string) => {
  return `${fName} ${lName}`
}

export const asUser = (e?: Maybe<object>): UserModel => {
  const user: Partial<User> = e ?? {}
  const company = user.company
  return {
    firstName: user.firstName ?? '',
    fullName: getFullName(user),
    nickName: user.nickName ?? '',
    userId: user.userId ?? '',
    hidden: user.hidden ?? true,
    isVeevan: user.isVeevan ?? company?.isVeeva ?? false,
    notificationFrequency: user?.notificationFrequency ?? '',
    lastName: user.lastName ?? '',
    title: user.title ?? '',
    photo: user.photo ?? '',
    company: {
      companyId: company?.companyId ?? '',
      name: company?.name ?? '',
      isVeeva: company?.isVeeva || false,
      photo: company?.homepage?.photo ?? '',
      homePageName: company?.homepage?.name ?? '',
      memberCount: company?.homepage?.memberCount || 0,
    },
    roles: user.roles?.edges
      ?.map(edge => (edge?.node ? (edge?.node as Partial<Role>) : (edge as Partial<Role>)))
      .filter(Boolean)
      .map(r => asRole(r)),
    email: user?.email ?? '',
    otherContact: user?.otherContact ?? '',
    profileVisible: user?.profileVisible || false,
    communityIds:
      (user.memberships?.edges.map(edge => edge?.node?.community?.communityId).filter(Boolean) as string[]) ?? [],
    createdTime: user.createdTime ?? '',
  }
}

export const asEvent = (e: Maybe<Partial<EventPartsFragment>> | undefined): EventModel => {
  return {
    eventId: e?.eventId ?? '',
    communityId: e?.communityId ?? '',
    title: e?.title ?? '',
    eventStart: new Date(e?.eventStart),
    eventEnd: new Date(e?.eventEnd),
    description: e?.description ?? null,
    location: e?.location ?? '',
    hidden: e?.hidden || false,
    createdTime: new Date(e?.createdTime ?? null),
    createdById: e?.createdById ?? '',
    repost: {
      eventId: e?.repost?.eventId ?? '',
      communityId: e?.repost?.communityId ?? '',
      hidden: e?.repost?.hidden ?? false,
    },
    community: {
      communityId: e?.community?.communityId ?? '',
      companyId: e?.community?.companyId ?? '',
      name: e?.community?.name ?? '',
    },
  }
}

const parsedDates = new Map<string, Date>()

export const asDate = (s?: string): Date | undefined => {
  if (!s) return undefined
  if (!parsedDates.has(s)) {
    parsedDates.set(s, new Date(s))
  }
  return parsedDates.get(s)
}

export const asRole = (e: Partial<Role>): RoleModel => {
  return {
    companyId: e.companyId ?? '',
    userId: e.userId ?? '',
    company: {
      companyId: e.company?.companyId ?? '',
      name: e.company?.name ?? '',
      isVeeva: e.company?.isVeeva || false,
    },
    modifiedTime: e.modifiedTime,
    description: e.description,
    member: {
      commercialLead: e.membership?.commercialLead ?? false,
      accountLead: e.membership?.accountLead ?? false,
      rdqLead: e.membership?.rdqLead ?? false,
    },
  }
}

type ResponseNotification = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>['data']>['notifications']
      >['edges'][0]
    >
  >['node']
>

export const asNotification = (e: ResponseNotification): NotificationModel => {
  return {
    notificationId: e.notificationId ?? '',
    notificationType: e.notificationType,
    userId: e.user?.userId ?? undefined,
    actorId: e.actorId ?? undefined,
    comment: e.comment
      ? {
          ...asComment(e.comment),
          post: { title: e.comment.post.title?.htmlWithMentions ?? '', postId: e.comment.post.postId },
        }
      : undefined,
    communityId: e?.community?.communityId ?? undefined,
    created: e.created,
    event: e.event ? asEvent(e.event as Partial<Event>) : undefined,
    extra: asNotificationExtra(e.extra ?? ''),
    post: e.post ? asPost(e.post as unknown as ResponsePost) : undefined,
    seenAt: e.seenAt,
    viewedAt: e.viewedAt,
  }
}

const asNotificationExtra = (json: string): NotificationExtraModel | undefined => {
  if (json === '') return undefined
  else {
    return JSON.parse(json)
  }
}

export type ResponseComment = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            QueryResult<GetMyCommunitiesPostsFullQuery, GetMyCommunitiesPostsFullQueryVariables>['data']
          >['posts']
        >['edges'][0]
      >
    >['node']
  >['publicComments']['comments'][0]
>

export const asComment = (e: Partial<ResponseComment>): CommentModel => {
  return {
    authorId: e.createdById ?? '',
    created_datetime: new Date(e.createdTime),
    commentId: e.commentId ?? '',
    postId: e.postId ?? '',
    media_url: e.mediaUrl ?? '',
    media_type: e.mediaType ?? null,
    content_title: e.contentTitle ?? '',
    story: e.story ?? null,
    likes: (e.likes?.edges.map(e => e?.node?.userId).filter(Boolean) ?? []) as string[],
    views: e.viewCount,
    hidden: Boolean(e?.hidden),
    veevanViewCount: e.veevanViewCount ?? 0,
    veevanLikeCount: e.veevanLikeCount ?? 0,
    isVeevanDiscussion: e.isVeevanDiscussion ?? false,
  }
}

export type ResponsePost = PostPartsFragment

const ucFirst = (a: string) => {
  return a[0].toUpperCase() + a.slice(1)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const asGQL = (typeName: string, obj: any): any => {
  const result = {
    __typename: typeName,
  } as any
  const lists = {
    likes: 'like',
    comments: 'comment',
    communities: 'community',
    memberships: 'membership',
    leaders: 'user',
    events: 'event',
    roles: 'role',
  }
  if (obj === null) return null
  for (const a of Object.keys(obj)) {
    if (a === 'id') {
      result[`${typeName.toLowerCase()}Id`] = obj.id
    } else {
      if (Object.keys(lists).includes(a)) {
        const prefix = a === 'likes' ? typeName : ''
        const listType = lists[a as keyof typeof lists]
        result[a] = {
          __typename: `${prefix}${ucFirst(listType)}Connection`,

          edges: obj[a].map((l: any) => {
            return {
              __typename: `${prefix}${ucFirst(listType)}Edge`,
              node: asGQL(`${prefix}${ucFirst(listType)}`, l),
            }
          }),
        }
      } else if (['user', 'company', 'post', 'community'].includes(a)) {
        result[a] = asGQL(ucFirst(a), obj[a])
      } else if (a === 'homepage') {
        result[a] = asGQL('Community', obj[a])
      } else if (a === 'lastPost') {
        result[a] = asGQL('Post', obj[a])
      } else if (a === 'lastComment') {
        result[a] = asGQL('Comment', obj[a])
      } else {
        result[a] = obj[a]
      }
    }
  }
  return result
}
/* eslint-enable */
export const asPost = (e: ResponsePost): PostModel => {
  return {
    authorId: e.createdById ?? '',
    created_datetime: e.createdTime,
    postId: e.postId,
    communityId: e.communityId,
    title: e.title ?? null,
    media_url: e.mediaUrl ?? '',
    media_type: e.mediaType ?? null,
    story: e.story ?? null,
    content_title: e.contentTitle ?? '',
    lastComment: {
      commenter_id: e.lastComment?.createdById ?? '',
      time: new Date(e.lastComment?.createdTime),
    },
    comment_count: e.commentCount ?? 0,
    likes: (e.likes?.edges.map(e => e?.node?.userId).filter(Boolean) ?? []) as string[],
    views: e.viewCount,
    last_activity_time: e.lastActivityTime,
    hidden: e.hidden ?? false,
    postType: e.postType ?? PostType.Post,
    comments: e.publicComments?.comments?.map(e => asComment(e as ResponseComment)).filter(Boolean) ?? [],
    viewed: e.viewed ?? false,
    veevanViewCount: e.veevanViewCount ?? 0,
    veevanLikeCount: e.veevanLikeCount,
    veevanCommentCount: e.veevanCommentCount,
    featured: e.featured ?? false,
    hasBeenReposted: e.repostsCount > 0,
    draft: e.draft ?? false,
    isRepost: e.isRepost ?? false,
    repostId: e.repostId ?? '',
    repost: e.repost
      ? {
          postId: e.repost.postId,
          createdById: e.repost.createdById ?? '',
          communityId: e.repost.communityId,
        }
      : undefined,
  }
}

export const getCommunityTypeText = (type: CommunityType | undefined): string => {
  switch (type) {
    case CommunityType.Public:
      return 'Public'
    case CommunityType.Private:
      return 'Private'
    case CommunityType.Homepage:
      return 'Company'
  }
  return ''
}

type ResponseCommunities = NonNullable<
  NonNullable<QueryResult<GetCommunitiesByNameQuery, GetCommunitiesByNameQueryVariables>['data']>['communities']
>

export const asCommunities = (e: ResponseCommunities): CommunityListViewModel[] => {
  return e.edges.map(edge => ({
    communityId: edge?.node?.communityId ?? '',
    name: edge?.node?.name ?? '',
    description: edge?.node?.description ?? '',
    memberCount: edge?.node?.memberCount ?? 0,
    photo: edge?.node?.photo ?? null,
    lastActivityTime: edge?.node?.lastPost?.lastActivityTime,
    type: getCommunityTypeText(edge?.node?.type),
  }))
}

export const asCommunity = (e: Maybe<Partial<Community>>): CommunityModel => {
  return {
    about: e?.about ?? null,
    description: e?.description ?? '',
    communityId: e?.communityId ?? '',
    events: e?.events?.edges?.map(event => {
      const node = event?.node
      return asEvent(node)
    }),
    leaderCount: e?.leaders?.totalCount ?? 0,
    memberCount: e?.memberCount ?? 0,
    name: e?.name ?? '',
    photo: e?.photo ?? null,
    company: asCompany(e?.company as Partial<Company>),
    lastPost: (e?.lastPost && asPost(e.lastPost)) ?? undefined,
    type: getCommunityTypeText(e?.type),
    isHomepage: e?.type === CommunityType.Homepage,
  }
}

type ResponseSuggestedCommunities = NonNullable<
  NonNullable<
    QueryResult<GetSuggestedCommunitiesQuery, GetSuggestedCommunitiesQueryVariables>['data']
  >['suggestedCommunities']
>

export const asRecommendedCommunities = (e: ResponseSuggestedCommunities): CommunityListViewModel[] => {
  return e.map(edge => ({
    communityId: edge?.communityId ?? '',
    name: edge?.name ?? '',
    description: edge?.description ?? '',
    memberCount: edge?.memberCount ?? 0,
    photo: edge?.photo ?? null,
    lastActivityTime: edge?.lastPost?.lastActivityTime,
    type: getCommunityTypeText(edge?.type),
  }))
}

const asCompany = (e: Maybe<Partial<Company>>): CompanyModel => {
  return {
    companyId: e?.companyId ?? '',
    name: e?.name ?? '',
    isVeeva: e?.isVeeva || false,
    photo: e?.homepage?.photo ?? '',
  }
}

// Not precise, rounds 'up' at 46 seconds, 46 minutes, etc.
export const toTimeAgo = (d: Date, d_now?: Date) => {
  if (!d) return ''
  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const month = 30.5 * day
  const year = 12 * month
  const oldDate = new Date(Date.parse(d.toString())).valueOf() / 1000
  const now = d_now ? new Date(Date.parse(d_now.toString())).valueOf() / 1000 : Date.now() / 1000
  const delta = now - oldDate
  if (delta > 549 * day) {
    return `${Math.round(delta / year)} years ago`
  }
  if (delta > 320 * day) {
    return `a year ago`
  }
  if (delta > 46 * day) {
    return `${Math.round(delta / month)} months ago`
  }
  if (delta > 26 * day) {
    return `a month ago`
  }
  if (delta > 36 * hour) {
    return `${Math.round(delta / day)} days ago`
  }
  if (delta > 22 * hour) {
    return `a day ago`
  }
  if (delta > 90 * minute) {
    return `${Math.round(delta / hour)} hours ago`
  }
  if (delta > 45 * minute) {
    return `an hour ago`
  }
  if (delta > 90) {
    return `${Math.round(delta / minute)} minutes ago`
  }
  if (delta > 45) {
    return `a minute ago`
  }
  const roundedDelta = Math.round(delta)
  return roundedDelta === 1 ? '1 second ago' : `${roundedDelta} seconds ago`
}

export const encodeRFC5987ValueChars = (str: string) => {
  //noinspection JSDeprecatedSymbols
  return (
    encodeURIComponent(str) // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      .replaceAll(/['()]/g, encodeURIComponent) // i.e., %27 %28 %29
      .replaceAll(/[*]/g, '%2A') // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      .replaceAll(/%(?:7C|60|5E)/g, decodeURIComponent)
  )
}

export const getLinkedinImage = (
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: {
      replace?: boolean | undefined
      state?: unknown
    }
  ) => void,
  setToast: (value: ((prevState: string) => string) | string) => void,
  setShowToast: (value: ((prevState: boolean) => boolean) | boolean) => void,
  setShowLinkedinLoading: (value: ((prevState: boolean) => boolean) | boolean) => void,
  setShowPhotoEdit: (value: ((prevState: boolean) => boolean) | boolean) => void,
  setCropImage: (
    value: ((prevState: File | null | undefined) => File | null | undefined) | File | null | undefined
  ) => void
) => {
  if (searchParams.get('provider') === 'linkedin') {
    if (searchParams.get('error')) {
      searchParams.delete('provider')
      searchParams.delete('error')
      setSearchParams(searchParams)
      setToast('Sorry, we were not able to find your LinkedIn profile photo')
      setShowToast(true)
    } else {
      setShowLinkedinLoading(true)
      setShowPhotoEdit(true)
      void getLinkedinPhoto().then(r => {
        searchParams.delete('provider')
        setSearchParams(searchParams)
        if (r.profile_image) {
          fetch(r.profile_image)
            .then(r => {
              void r.blob().then(blob => {
                setShowLinkedinLoading(false)
                setCropImage(new File([blob], 'linkedin-profile', { type: blob.type }))
              })
            })
            .catch(() => {
              setToast('Sorry, we were not able to find your LinkedIn profile photo')
              setShowToast(true)
            })
        } else {
          setToast('Sorry, we were not able to find your LinkedIn profile photo')
          setShowToast(true)
        }
      })
    }
  }
}

export const getCroppedUploadHandler = (
  setUploading: (value: ((prevState: boolean) => boolean) | boolean) => void,
  createMedia: (
    options?: MutationFunctionOptions<CreateMediaMutation, CreateMediaMutationVariables>
  ) => Promise<FetchResult<CreateMediaMutation>>,
  setCreateMediaResponse: (
    value:
      | ((prevState: CreateMediaMutation | undefined) => CreateMediaMutation | undefined)
      | CreateMediaMutation
      | undefined
  ) => void,
  setMediaUrl?: (value: ((prevState: string) => string) | string) => void,
  setMediaType?: (value: ((prevState: MediaType) => MediaType) | MediaType) => void
) => {
  return (blob: Blob, contentType: string, fileName: string) => {
    const name = fileName.length >= 100 ? fileName.split('.')[0].slice(0, 90) + '.' + fileName.split('.')[1] : fileName
    void createMedia({ variables: { content_type: contentType, filename: name } }).then(async response => {
      const uploadUrl = response.data?.createMedia?.uploadUrl
      const uploadedFile = await blob.arrayBuffer()
      if (response.data?.createMedia) setCreateMediaResponse(response.data)
      if (uploadUrl) {
        setUploading(true)
        try {
          await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Disposition': "attachment; filename*=UTF-8''" + encodeRFC5987ValueChars(name),
              'Content-Type': contentType,
            },

            body: uploadedFile,
          })
          if (setMediaUrl) {
            setMediaUrl(URL.createObjectURL(blob) ?? '')
          }
          if (setMediaType) {
            setMediaType(response.data?.createMedia?.mediaType ?? MediaType.Other)
          }
        } finally {
          setUploading(false)
        }
      }
    })
  }
}

export const emailFrequencyAsString = (str: string): string => {
  switch (str as NotificationEmailFrequency) {
    case NotificationEmailFrequency.Immediate || 'Immediate':
      return 'Immediate'
    case NotificationEmailFrequency.Daily || 'Daily':
      return 'Daily'
    case NotificationEmailFrequency.Weekly || 'Weekly':
      return 'Weekly'
    default:
      return ''
  }
}

export type HtmlValue = Html
export type HtmlWithMentionsValue = HtmlWithMentions
export type HtmlOrStringValue = HtmlValue | HtmlWithMentionsValue | string

export const asHtml = (str: Maybe<string>): Maybe<HtmlValue> => (str == undefined ? str : { html: str })

export const asHtmlWithMentions = (str: Maybe<string> | undefined): Maybe<HtmlWithMentionsValue> =>
  str == undefined ? null : { htmlWithMentions: str }

export const asString = (value: Maybe<HtmlOrStringValue> | undefined): string => {
  if (value === null || value === undefined) {
    return ''
  } else if (typeof value === 'string') {
    return value
  } else if ('html' in value) {
    return value.html ?? ''
  } else if ('htmlWithMentions' in value) {
    return value.htmlWithMentions ?? ''
  }
  return ''
}

export const removeTags = (str: Maybe<Html> | Maybe<HtmlWithMentions> | string | undefined): Maybe<string> => {
  const value = typeof str === 'string' ? str : asString(str)
  if (!value) return null
  return value.replaceAll(/<[^>]*>?/gm, '')
}

export const cleanupHTML = (str: Maybe<string>): Maybe<string> => {
  return str ? str?.replace(/<img[^>]*>/g, '').replaceAll('﻿', '') : null
}

export const decodeHTMLSymbols = (str: Maybe<string>): Maybe<string> => {
  return str ? str?.replace(/&lt;/g, '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&') : null
}

export const htmlToText = (str: Maybe<Html> | Maybe<HtmlWithMentions> | string): Maybe<string> => {
  return decodeHTMLSymbols(removeTags(str))
}

export const shortenString = (str: Maybe<string> | undefined, maxLength: number): Maybe<string> | undefined => {
  return str ? (str.length <= maxLength ? str : str.slice(0, Math.max(0, str.lastIndexOf(' ', maxLength)))) : str
}

// Inspired by: https://stackoverflow.com/a/40167837
export const shortenHTMLString = (
  str: Maybe<Html> | Maybe<HtmlWithMentions> | undefined,
  maxLength: number
): Maybe<string> | undefined => {
  if (str === null || str === undefined) return str
  const value = asString(str)
  if (!value) return value
  const regex = /(<([^>]+)>)/gi
  let s = value.slice(0, maxLength) // ignores tag lengths for solution brevity
  s = s.replace(/<[^>]*$/, '') // rm any trailing partial tags
  if (value.length <= maxLength) {
    return s
  }

  s = s.slice(0, Math.max(0, s.lastIndexOf(' ', maxLength)))
  const tags = s.match(regex)

  // find out which tags are unmatched
  const openTagsSeen: string[] = []
  if (tags == null) {
    return value.length <= maxLength ? value : value.slice(0, Math.max(0, value.lastIndexOf(' ', maxLength)))
  } else {
    for (const tag of tags) {
      // don't try to match <br>
      if (tag.match(/<[^>]+>/) !== null && tag != '<br>') {
        openTagsSeen.push(tag)
      } else {
        openTagsSeen.pop()
      }
    }

    // reverse and close unmatched tags
    openTagsSeen.reverse()
    for (const tag of openTagsSeen) {
      const matches = tag.match(/\w+/)
      if (matches != null) {
        s += '</' + matches[0] + '>'
      }
    }
    return s
  }
}

// debounce adapted from https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: NodeJS.Timeout

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

// Flag emoji character can be created by shifting each character of the two-character country code
// The following algorithm is taken from [this](https://dev.to/jorik/country-code-to-flag-emoji-a21) blog post
export const getCountryFlag = (countryCode?: string) => {
  if (countryCode && countryCode.length == 2) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + (char.codePointAt(0) ?? 0))
    return String.fromCodePoint(...codePoints)
  } else {
    return null
  }
}

export const getStartOfCurrentDay = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today.toISOString()
}

export const UTCtoLocal = (UTCString: string) => {
  const localDate = new Date(UTCString)
  return localDate.toLocaleString()
}

const validTLDs =
  'com?|net|org|edu|gov|us|jp|de|uk|fr|br|it|ru|es|me|pl|ca|au|cn|in|nl|info|eu|ch|id|at|kr|cz|mx|be|tv|se|tr|tw|al|ua|ir|vn|cl|sk|ly|cc|to|no|fi|pt|dk|ar|hu|tk|gr|il|news|ro|my|biz|ie|za|nz|sg|ee|th|io|xyz|pe|bg|hk|rs|lt|link|ph|club|si|site|mobi|by|cat|wiki|la|ga|xxx|cf|hr|ng|jobs|online|kz|ug|gq|ae|is|lv|pro|fm|tips|ms|sa|app|ac|ad|af|ag|ai|am|an|ao|help'

export const LINK_REGEX = new RegExp(
  `https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}(\\.(${validTLDs})){1,6}\\b[-a-zA-Z0-9()@:%_+.~#?&/=]*|[-a-zA-Z0-9@:%._+~#=]{1,256}(\\.(${validTLDs})){1,6}\\b[-a-zA-Z0-9()@:%_+.~#?&/=]*`,
  'gim'
)

export const WISTIA_REGEX = new RegExp(String.raw`^https:\/\/fast\.wistia\.com\/embed\/medias\/\w+\.jsonp$`, 'gim')
export const WISTIA_REGEX_ALT = new RegExp(String.raw`^https:\/\/veevasystems\.wistia\.com\/medias\/\w+$`, 'gim')

export const contentIconSrc = (mediaType: MediaType | undefined) => {
  switch (mediaType) {
    case MediaType.Image:
      return image_icon
    case MediaType.Doc:
      return doc_icon
    case MediaType.Spreadsheet:
      return spreadsheet_icon
    case MediaType.Presentation:
      return presentation_icon
    case MediaType.Pdf:
      return pdf_icon
    case MediaType.Video:
      return video_icon
    case MediaType.Brainshark:
      return video_icon
    case MediaType.Vpk:
      return vpk_icon
    case MediaType.Zip:
      return zip_icon
    case MediaType.Other:
      return generic_icon
    default:
      return generic_icon
  }
}

export const getGlobalLeadList = (
  accountLead?: boolean,
  commercialLead?: boolean,
  rdqLead?: boolean,
  joinString = '\n'
): string => {
  return [
    ...(accountLead ? ['Global Account Lead'] : []),
    ...(commercialLead ? ['Global Commercial Lead'] : []),
    ...(rdqLead ? ['Global R&D and Quality Lead'] : []),
  ].join(joinString)
}

export const getAccountPartnerTypeDisplay = (
  leader: boolean,
  accountLead: boolean,
  commercialLead: boolean,
  rdqLead: boolean
): string => {
  if (!leader) return 'Member'
  if (leader && !accountLead && !commercialLead && !rdqLead) {
    return 'Account Partner'
  }
  return getGlobalLeadList(accountLead, commercialLead, rdqLead)
}

const nameSort = (a: Membership, b: Membership) => {
  return (
    a?.user?.firstName?.localeCompare(b?.user?.firstName || '', undefined, { sensitivity: 'base' }) ||
    a?.user?.lastName?.localeCompare(b?.user?.lastName || '', undefined, { sensitivity: 'base' }) ||
    0
  )
}

const leaderSort = (a: Membership, b: Membership) => {
  return (
    `${b?.accountLead}`.localeCompare(`${a?.accountLead}`, undefined, { sensitivity: 'base' }) ||
    `${b?.commercialLead}`.localeCompare(`${a?.commercialLead}`, undefined, { sensitivity: 'base' }) ||
    `${b?.rdqLead}`.localeCompare(`${a?.rdqLead}`, undefined, { sensitivity: 'base' }) ||
    `${b?.leader}`.localeCompare(`${a?.leader}`, undefined, { sensitivity: 'base' })
  )
}

// Roles are sorted a -> z -> null (and vice versa). In case of 2 roles being equal we will sort alphabetically based on the member's name.
const sortRoles = (asc: boolean, members?: Membership[], roles?: Map<string, Maybe<string>>) => {
  function sortNulls(asc: boolean) {
    return function (a: Membership, b: Membership) {
      const roleA = roles?.get(a.user?.userId ?? '') ?? ''
      const roleB = roles?.get(b.user?.userId ?? '') ?? ''

      if (asc) {
        return roleA == roleB ? nameSort(a, b) : roleB && roleA ? roleA.localeCompare(roleB) : roleB ? 1 : -1
      }
      return roleA == roleB ? nameSort(b, a) : roleB && roleA ? roleB.localeCompare(roleA) : roleA ? 1 : -1
    }
  }

  return members?.sort(sortNulls(asc))
}

export const sortMembers = (
  members?: Membership[],
  roles?: Map<string, Maybe<string>>,
  sortType?: string,
  asc?: boolean
) => {
  switch (sortType) {
    case 'NAME':
      return asc
        ? members?.sort((a, b) => {
            return nameSort(a, b)
          })
        : members?.sort((a, b) => {
            return nameSort(b, a)
          })
    case 'COMPANY':
      return asc
        ? members?.sort((a, b) => {
            return (
              a?.user?.company?.name?.localeCompare(b?.user?.company?.name || '', undefined, { sensitivity: 'base' }) ||
              nameSort(a, b)
            )
          })
        : members?.sort((a, b) => {
            return (
              b?.user?.company?.name?.localeCompare(a?.user?.company?.name || '', undefined, { sensitivity: 'base' }) ||
              nameSort(a, b)
            )
          })
    case 'TYPE':
      return asc
        ? members?.sort((a, b) => {
            return leaderSort(a, b) || nameSort(a, b)
          })
        : members?.sort((a, b) => {
            return leaderSort(b, a) || nameSort(a, b)
          })
    case 'ROLE':
      return sortRoles(Boolean(asc), members, roles)
    default:
      return members?.sort((a, b) => {
        return leaderSort(a, b) || nameSort(a, b)
      })
  }
}

export const sortCommunityList = (companyId: Maybe<string>, communityList?: CommunityListViewModel[]) => {
  const sortedList = communityList ? [...communityList] : []
  return sortedList?.sort((a, b) => {
    const aCompanyId = a.companyId
    const bCompanyId = b.companyId
    if (aCompanyId === companyId && bCompanyId !== companyId) return -1
    if (aCompanyId !== companyId && bCompanyId === companyId) return 1
    if (aCompanyId && !b.companyId) return -1
    if (!aCompanyId && b.companyId) return 1
    return a.name.localeCompare(b.name)
  })
}

export function communitiesToCommunitiesListView(
  allMyCommunities: GetMyCommunitiesQuery | undefined,
  canPostIds: Set<string>
) {
  return (
    (allMyCommunities &&
      canPostIds &&
      allMyCommunities.currentUser?.communities?.edges.map(edge => ({
        communityId: edge?.node?.communityId ?? '',
        companyId: edge?.node?.companyId ?? '',
        name: edge?.node?.name ?? '',
        description: edge?.node?.description ?? '',
        memberCount: edge?.node?.memberCount ?? 0,
        photo: edge?.node?.photo ?? null,
        canPost: canPostIds.has(edge?.node?.communityId ?? '') ?? false,
        lastActivityTime: edge?.node?.lastPost?.lastActivityTime,
        type: getCommunityTypeText(edge?.node?.type),
      }))) ??
    []
  )
}

export const getCommentsNotificationInfo = (
  comments: { commentId: string; likes: string[]; authorId: string }[]
): Array<InputMaybe<NotificationInfo>> => {
  return comments.map(comment => ({
    id: comment.commentId ?? '',
    likers: comment.likes,
    mentioners: comment.authorId ? [comment.authorId] : [],
    allMentioners: comment.authorId ? [comment.authorId] : [],
  }))
}

export const getOfficeHomeLocation = (primaryLocation?: LocationModel, secondaryLocation?: LocationModel) => {
  const hasPrimaryLocation = primaryLocation?.city || primaryLocation?.state || primaryLocation?.country
  const hasSecondaryLocation = secondaryLocation?.city || secondaryLocation?.state || secondaryLocation?.country

  // Office is always primary location if it exists unless it is overwritten by the secondary location's type being office
  const office =
    hasSecondaryLocation && secondaryLocation.type == 'OFFICE'
      ? secondaryLocation
      : hasPrimaryLocation
        ? primaryLocation.type == 'HOME'
          ? secondaryLocation
          : primaryLocation
        : null

  // Home is always secondary location if it exists unless it is overwritten by the primary location's type being home
  const home =
    hasPrimaryLocation && primaryLocation.type == 'HOME'
      ? primaryLocation
      : hasSecondaryLocation
        ? secondaryLocation.type == 'OFFICE'
          ? primaryLocation
          : secondaryLocation
        : null

  return { office, home }
}

export const createURLwithQueryParams = (url: string, queryParams: { key: string; value: string }[]) => {
  const urlAsURL = new URL(url)
  const searchParams = new URLSearchParams(urlAsURL.search)
  for (const param of queryParams) {
    if (searchParams.has(param.key)) searchParams.delete(param.key)
    searchParams.append(param.key, param.value)
  }
  return `${urlAsURL.origin}${urlAsURL.pathname === '/' ? '' : urlAsURL.pathname}?${searchParams.toString()}`
}

export const formatLinks = (input = '') => {
  const matches = Array.from(input.matchAll(LINK_REGEX))
  const result: (string | JSX.Element)[] = []
  let index = 0
  for (const m of matches) {
    const url = (m[0].startsWith('www') ? 'https://' : '') + m[0]
    result.push(
      input.slice(index, m.index),
      <a target="_blank" href={url}>
        {m[0]}
      </a>
    )
    index += (m?.index ?? 0) + m[0].length
  }
  result.push(input.slice(index))
  return <>{...result.filter(Boolean)}</>
}

export const getNormalizedTokens = (text: string) => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replaceAll(/[\u0300-\u036F]/g, '')
    .split(/\b/)
    .filter(x => x !== '')
}
export type UserNameFields = { userId: string; firstName: string; lastName: string; nickName: string }

export const userByNameSort = (a?: UserNameFields, b?: UserNameFields) =>
  `${a?.firstName?.toLowerCase()} ${a?.lastName?.toLowerCase()} ${a?.nickName?.toLowerCase()}`.localeCompare(
    `${b?.firstName?.toLowerCase()} ${b?.lastName?.toLowerCase()} ${b?.nickName?.toLowerCase()}`
  )

export const peopleSearchSort = (
  a: Suggestion,
  b: Suggestion,
  search: string,
  names: string[],
  communityId?: string,
  memberIdSet?: Set<string | undefined>
) => {
  const searchNormed = search
    .toLowerCase()
    .replace(/\u0300-\u036F/, '')
    .replace('-', ' ')
  const aFirstName = a.user?.firstName?.toLowerCase()
  const aLastName = a.user?.lastName?.toLowerCase()
  const aNickName = a.user?.nickName?.toLowerCase()
  const bFirstName = b.user?.firstName?.toLowerCase()
  const bLastName = b.user?.lastName?.toLowerCase()
  const bNickName = b.user?.nickName?.toLowerCase()
  const aIsMember = a.user?.userId && memberIdSet?.has(a.user?.userId)
  const bIsMember = b.user?.userId && memberIdSet?.has(b.user?.userId)
  const aExact = `${aFirstName} ${aLastName}` == searchNormed
  const bExact = `${bFirstName} ${bLastName}` == searchNormed
  const aMatchesFirst = aFirstName?.startsWith(searchNormed)
  const bMatchesFirst = bFirstName?.startsWith(searchNormed)
  const aMatchesLast = aLastName?.startsWith(searchNormed)
  const bMatchesLast = bLastName?.startsWith(searchNormed)
  const aMatchesNickname = aNickName?.startsWith(searchNormed)
  const bMatchesNickname = bNickName?.startsWith(searchNormed)
  const aIsGroup = a.id === communityId
  const bIsGroup = b.id === communityId
  const memberOrderList = memberIdSet ? Array.from(memberIdSet) : []
  if (aIsGroup && !bIsGroup) return -1
  if (!aIsGroup && bIsGroup) return 1
  if (!aExact && bExact) return 1
  if (aExact && !bExact) return -1
  if (aIsMember && !bIsMember) return -1
  if (!aIsMember && bIsMember) return 1
  if (aIsMember && bIsMember && a?.user?.userId && b?.user?.userId)
    return memberOrderList.indexOf(a?.user?.userId) - memberOrderList.indexOf(b?.user?.userId)
  if (names.length === 1) {
    if (aMatchesFirst && !bMatchesFirst) return -1
    if (!aMatchesFirst && bMatchesFirst) return 1
    if (aMatchesLast && !bMatchesLast) return -1
    if (!aMatchesLast && bMatchesLast) return 1
    if (aMatchesNickname && !bMatchesNickname) return -1
    if (!aMatchesNickname && bMatchesNickname) return 1
  }
  return 0
}

export const getEmailParts = (email: string | null): { username: string; domain: string } | null => {
  if (!email) return null
  const username = email?.split('@')[0]
  const domain = email?.split('@')[1]
  return { username, domain }
}

export function searchCache(globalSearch: string) {
  const prefixCache = globalThis.prefixCache
  const searchTokens = getNormalizedTokens(globalSearch)
  const results = new Map<string, number>()
  for (const tok of searchTokens) {
    for (const match of prefixCache?.get(tok) ?? []) {
      results.set(match, (results.get(match) || 0) + 1)
    }
  }

  // filter out results that do not match all tokens - makes the search an AND rather than OR
  return Array.from(results.keys()).filter(k => results.get(k) === searchTokens.length)
}

export const getPostPath = (
  community: Maybe<{ companyId?: Maybe<string>; communityId?: Maybe<string> }> | undefined,
  post?: Maybe<{ postType?: PostType; postId: string }>,
  fromContentPage?: boolean,
  commentId?: string,
  isVeevaDiscussion?: boolean
): string => {
  if (!community || !post) return ''
  const isContentLink = fromContentPage == undefined ? post.postType === PostType.Content : fromContentPage
  return community.companyId
    ? `/companies/${community.companyId}/${isContentLink ? 'content' : 'posts'}/${post.postId}${
        commentId ? `?c=${commentId}${isVeevaDiscussion ? '&vd=1' : ''}` : ''
      }`
    : `/communities/${community.communityId}/${isContentLink ? 'content' : 'posts'}/${post.postId}${
        commentId ? `?c=${commentId}${isVeevaDiscussion ? '&vd=1' : ''}` : ''
      }`
}

export const getEventPath = (
  community: Maybe<{ companyId?: Maybe<string>; communityId?: Maybe<string> }> | undefined,
  eventId: string
): string => {
  if (!community) return ''
  return community.companyId
    ? `/companies/${community.companyId}/events?e=${eventId}`
    : `/communities/${community.communityId}/events?e=${eventId}`
}

// convert wistia links from veevasystems.wistia.com/medias/[...] to fast.wistia.com/embed/medias/[...].jsonp
export const updateWistiaLink = (link: Maybe<string>) => {
  if (!link) return null
  if (WISTIA_REGEX.test(link)) {
    return link
  }
  try {
    const url = new URL(link)
    const path = `/embed${url.pathname}.jsonp`
    return `https://fast.wistia.com${path}`
  } catch {
    return link
  }
}

type LeaderData = MembershipPartsFragment & {
  community?: Maybe<{
    communityId?: Maybe<string>
    __typename?: string
  }>
}

export const updateLeadersCache = (cache: ApolloCache<unknown>, data: Maybe<LeaderData>) => {
  cache.modify({
    id: cache.identify(data?.community ?? {}),
    fields: {
      members: existingMembers => {
        const newLeaderRef = cache.writeFragment({
          data,
          fragment: MembershipPartsFragmentDoc,
        })

        // if we are adding an ap that was previously a member, we don't need to re-add them to the members cache
        if (existingMembers.edges.some((e: { node: Reference | undefined }) => e.node?.__ref == newLeaderRef?.__ref)) {
          return existingMembers
        }

        return {
          ...existingMembers,
          totalCount: existingMembers.totalCount + 1,
          edges: [...existingMembers.edges, { __typename: 'MembershipEdge' as const, node: newLeaderRef }],
        }
      },
    },
  })
}

export const removeMemberCache = (cache: ApolloCache<unknown>, removeMember: Maybe<RemoveMemberMutation>) => {
  const userId = removeMember?.removeMember?.user?.userId ?? ''
  const communityId = removeMember?.removeMember?.community?.communityId ?? ''
  const normalizedId = cache.identify({ __typename: 'Membership' as const, userId, communityId })
  // rather than trying to worry about removing the membership everywhere it might be used,
  // we can just delete the membership object itself from the cache which will update related queries accordingly
  cache.evict({ id: normalizedId })
  cache.gc()
}

export const checkConfidentialWarning = (story: Maybe<string> | undefined): boolean => {
  return (
    !!story?.match(new RegExp(/<pre class="ql-syntax" spellcheck="false">.*?<\/pre>/, 'is')) ||
    !!story?.match(new RegExp(/<pre class=\\"ql-syntax\\">.*?<\/pre>/, 'is')) ||
    !!story?.match(new RegExp(/<div class="ql-code-block-container" spellcheck="false">.*?<\/div>/, 'is')) ||
    !!story?.match(new RegExp(/<div class=\\"ql-code-block-container\\">.*?<\/div>/, 'is'))
  )
}

export const secondsToTimeDisplay = (seconds?: number) => {
  if (!seconds) return '00:00'
  const minutes = Math.floor(seconds / 60)
  const roundedSeconds = Math.ceil(seconds % 60)
  return `${minutes === 0 ? '00' : minutes}:${
    roundedSeconds.toString().length == 2 ? roundedSeconds : `0${roundedSeconds}`
  }`
}

const getDateSuffix = (date: number) => {
  if (date > 3 && date < 21) return 'th'
  switch (date % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const getDayMonthYear = (dateString: string) => {
  const date = new Date(dateString)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return months[date.getMonth()] + ' ' + date.getDate() + getDateSuffix(date.getDate()) + ', ' + date.getFullYear()
}

export const getTimeAMPM = (dateString: string) => {
  const date = new Date(dateString)
  let hours = date.getHours()
  let minutes: string | number = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  return hours + ':' + minutes + ampm
}

export const updatePermissions = (
  cache: ApolloCache<unknown>,
  community: Maybe<{ communityId?: Maybe<string>; canPost?: Maybe<boolean>; canEdit?: Maybe<boolean> }>
) => {
  const communityId = community?.communityId
  const canPost = community?.canPost
  const canEdit = community?.canEdit
  if (!communityId || (canPost === undefined && canEdit === undefined)) return
  const oldPerms = cache.readQuery<GetPermissionsQuery>({
    query: GetPermissionsDocument,
  })
  cache.updateQuery({ query: GetPermissionsDocument }, data => {
    const result = {
      permissions: {
        __typename: 'Permissions' as const,
        canPostIds: new Array<string>(),
        canEditIds: new Array<string>(),
        sysAdminId: oldPerms?.permissions?.sysAdminId ?? '',
        relAdminId: oldPerms?.permissions?.relAdminId ?? '',
        summitAdminId: oldPerms?.permissions?.summitAdminId ?? '',
      },
    }
    const existingCanPostIds = data?.permissions?.canPostIds ?? []
    const existingCanEditIds = data?.permissions?.canEditIds ?? []
    if (typeof canPost === 'boolean') {
      const canPostIds: Set<string> = new Set(existingCanPostIds)
      if (canPost) {
        canPostIds.add(communityId)
      } else {
        canPostIds.delete(communityId)
      }
      result.permissions.canPostIds = [...canPostIds]
    } else {
      result.permissions.canPostIds = existingCanPostIds
    }
    if (typeof canEdit === 'boolean') {
      const canEditIds: Set<string> = new Set(existingCanEditIds)
      if (canEdit) {
        canEditIds.add(communityId)
      } else {
        canEditIds.delete(communityId)
      }
      result.permissions.canEditIds = [...canEditIds]
    } else {
      result.permissions.canEditIds = existingCanEditIds
    }
    return result
  })
}
export const photoStyle = (photo: Maybe<string>) => (photo ? { backgroundImage: `url(${photo})` } : {})

export const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const getOp = (doc: DocumentNode) =>
  doc.definitions.find(i => i as OperationDefinitionNode) as OperationDefinitionNode

export const getOpName = (doc: DocumentNode): string | undefined => getOp(doc)?.name?.value

export const getFieldName = (
  cache: InMemoryCache,
  doc: DocumentNode,
  fieldName?: string,
  variables?: Record<string, unknown>
) => {
  const op = getOp(doc)
  const field = fieldName
    ? (op.selectionSet.selections.find(i => (i as FieldNode).name.value === fieldName) as FieldNode)
    : (op.selectionSet.selections[0] as FieldNode)
  return cache.policies.getStoreFieldName({
    typename: 'Query',
    fieldName: field.name.value,
    field,
    variables,
  })
}

export const invalidateQuery = (cache: ApolloCache<unknown>, doc: DocumentNode) => {
  const fieldName = getFieldName(cache as InMemoryCache, doc)
  if (cache.evict({ id: 'ROOT_QUERY', fieldName })) {
    cache.gc()
  }
}

export const getPluralizedPost = (count?: number) => {
  return count === 1 ? 'post' : 'posts'
}

export const getSafeLocationState = <T,>(state: unknown): T | null => {
  return state as T | null
}

export const removeWhiteSpace = (value: string) => {
  return value.replaceAll(/\s+/g, ' ').trim()
}

export const trimURL = (url: string) => {
  return normalizeUrl(url, {
    stripWWW: true,
    stripProtocol: true,
  })
}

export const isSafeLink = (href: string, hostnames: string[]) => {
  let domain = normalizeUrl(href)
  try {
    const url = new URL(domain)
    domain = url.hostname
  } catch {
    return false // invalid URL
  }

  for (const hostname of hostnames) {
    if (domain == hostname || domain.endsWith(`.${hostname}`)) {
      return true
    }
  }
  return false
}

export const trimFromDelimiters = (title: string) => {
  // colon, hyphen, double hyphen, en dash, em dash
  return title.replace(new RegExp(String.raw`^.*?([:–—])|^.*?([\-]{1,2})`), '').trim()
}

export const getVideoStartTime = (rawStartTime: string): number | null => {
  const matches = rawStartTime?.match(/(?<hours>\d\d):(?<minutes>\d\d):(?<seconds>\d\d)/)
  if (matches?.groups) {
    return (
      Number.parseInt(matches.groups.hours) * 3600 +
      Number.parseInt(matches.groups.minutes) * 60 +
      Number.parseInt(matches.groups.seconds)
    )
  }
  return null
}

export const getWistiaId = (videoUrl: Maybe<string>): string | undefined => {
  return videoUrl?.match(/(?<wistiaId>\w+)\.jsonp/)?.groups?.wistiaId
}

export const getVideoLength = async (wistiaId: string | undefined) => {
  if (!wistiaId) return null
  const videoDataResp = await fetch(`https://fast.wistia.com/embed/medias/${wistiaId}.json`)
  const videoData = await videoDataResp.json()
  return videoData.media.duration
}

export const getMaxTypeaheadSlots = (isVeevan: boolean): number => {
  return isVeevan ? 15 : 14
}

export const updatePostsCache = async (
  newActivity: SubscriptionInfo[],
  getPostActivity: LazyQueryExecFunction<GetNewPostActivityQuery, Exact<{ postIds: string | string[] }>>,
  client: ApolloClient<object>,
  communityId: string,
  authUserId: string,
  setNewActivity?: Dispatch<SetStateAction<SubscriptionInfo[]>>,
  document?: DocumentNode
) => {
  const ids = newActivity.map(a => a.parentId || a.objId)
  const { data: postActivity } = await getPostActivity({ variables: { postIds: ids }, fetchPolicy: 'network-only' }) // update cache so post activity time is reflected correctly
  const oldData = client.readQuery<GetPostsQuery>({
    query: document ?? GetPostsDocument,
    variables: { communityId: communityId, userId: authUserId, pageSize: POSTS_PAGE_SIZE },
  })

  let newPosts = [...(oldData?.posts?.edges ?? [])]
  const nonDraftIndex = newPosts.findIndex(p => !p?.node?.draft)

  // remove any duplicates, which usually means we are reordering a post higher due to recent activity
  newPosts.splice(nonDraftIndex, 0, ...(postActivity?.posts?.edges ?? []))
  newPosts = newPosts.filter((p1, i, arr) => arr.findIndex(p2 => p1?.node?.postId === p2?.node?.postId) === i)

  const newPostsData = {
    ...oldData,
    posts: {
      ...oldData?.posts,
      edges: newPosts?.slice(0, POSTS_PAGE_SIZE),
    },
  }
  client.writeQuery({
    query: document ?? GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
    data: newPostsData,
  })
  setNewActivity?.([])
}

export const setFetchedActivity = (
  r: GetPostsQuery | GetMyCommunitiesPostsQuery,
  setNewActivity?: React.Dispatch<React.SetStateAction<SubscriptionInfo[]>>,
  postsData?: GetPostsQuery | GetMyCommunitiesPostsQuery
) => {
  const fetchedPosts = new Map<string, string | undefined>()
  const existingPosts = new Map<string, string | undefined>()
  for (const p of r?.posts?.edges ?? []) {
    if (p?.node?.postId) {
      fetchedPosts.set(p?.node?.postId, p?.node?.lastComment?.commentId ?? undefined)
    }
  }
  const fetchedPostsIds = Array.from(fetchedPosts.keys())
  for (const p of postsData?.posts?.edges ?? []) {
    if (p?.node?.postId) {
      existingPosts.set(p?.node?.postId, p?.node?.lastComment?.commentId)
    }
  }
  const existingPostsIds = new Set(Array.from(existingPosts.keys()))
  const newPosts = fetchedPostsIds.filter(p => !existingPostsIds.has(p) || existingPosts.get(p) != fetchedPosts.get(p))
  if (newPosts.length > 0) {
    setNewActivity?.(a => {
      const activity = [...a]
      activity.push(
        ...newPosts.map(p => {
          // if we have a lastCommentId the new activity is a new comment, otherwise it's a new post
          if (fetchedPosts.get(p)) {
            return {
              objId: fetchedPosts.get(p),
              parentId: p,
            } as SubscriptionInfo
          }
          return {
            objId: p,
          } as SubscriptionInfo
        })
      )
      return activity
    })
  }
}
export const padDigit = (digit: number) => digit.toString().padStart(2, '0')

export const getThumbnailProgressInfo = async (hashed_id: string) => {
  const response = await fetch(`https://fast.wistia.com/embed/medias/${hashed_id}.json`)
  const json = await response.json()
  // The thumbnail information is not always at the same index for each upload
  for (let i = 0; i < json.media.assets.length; i++) {
    if (json.media.assets[i].type == 'still_image') {
      return json.media.assets[i].progress
    }
  }
  return -1
}

export const isDownloadableMediaType = (mediaType: MediaType) => {
  return [
    MediaType.Doc,
    MediaType.Other,
    MediaType.Pdf,
    MediaType.Presentation,
    MediaType.Spreadsheet,
    MediaType.Vpk,
    MediaType.Zip,
  ].includes(mediaType)
}
