import 'react'
import { Navigate, useParams } from 'react-router'
import { ResolveReleaseLinkDocument } from '~/api/generated/graphql'
import { useQuery } from '@apollo/client'

function trimSlashes(str: string) {
  return str.replaceAll(/^\/+|\/+$/g, '')
}

export const CustomLink = () => {
  const { '*': splat = '' } = useParams()
  const customUrl = trimSlashes(splat).toLowerCase()
  const { data, loading } = useQuery(ResolveReleaseLinkDocument, { variables: { customUrl } })

  if (loading) return <div data-testid="loading-link" />

  const releaseId = data?.releases?.edges?.[0]?.node?.releaseId
  const path = releaseId ? `/releases/${releaseId}` : '/'
  return <Navigate to={path} replace />
}
