import React, { SyntheticEvent, useEffect, useMemo } from 'react'
import '@css/pages/feed/SuggestedCommunities.scss'
import RecommendedCommunityItem from '~/pages/feed/RecommendedCommunityItem'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import {
  GetCompanyNameDocument,
  GetRecommendedCommunityDocument,
  GetSuggestedCommunitiesDocument,
  GetUserCommunitiesDocument,
} from '~/api/generated/graphql'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { useAuth } from '~/auth/Auth'
import { Link } from 'react-router-dom'
import JoinCommunityButton from '~/common/JoinCommunityButton'
import { useCommunity } from '~/contexts/CommunityContext'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Maybe } from '~/api/generated/graphql'
import { elementClicked, elementClickedNoEvent } from '~/common/EventLogger'
import { useQuery } from '@apollo/client'

type SingleContentRecommendedViewProps = {
  loading: boolean
  communityIds: Maybe<Maybe<string>[]>
  recommendedCommunityIds?: string[]
  setLoadingRecommended?: (b: boolean) => void
}
const SingleContentRecommendedView = ({
  loading,
  communityIds,
  recommendedCommunityIds,
  setLoadingRecommended,
}: SingleContentRecommendedViewProps) => {
  const { communityId, loading: communityLoading } = useCommunity()
  const isMember = communityId && communityIds?.includes(communityId)
  const otherRecommended = (recommendedCommunityIds?.filter(c => c !== communityId).length ?? 0) > 0
  const { data: basicCommunityData, loading: commLoading } = useQuery(GetRecommendedCommunityDocument, {
    variables: { id: communityId || '' },
    skip: !communityId,
  })

  const isLoading = loading || (commLoading && !basicCommunityData) || (communityLoading && !communityId)

  useEffect(() => {
    setLoadingRecommended?.(isLoading)
  }, [setLoadingRecommended, isLoading])

  if (isLoading) return null
  if (isMember && !otherRecommended) return <div data-testid={'empty'} />

  return isMember ? (
    <div className={'mobile-recommendations-container'}>
      <h3>Looking for more?</h3>
      <p>We can help get you connected!</p>
      <Link to={'/recommendedcommunities'}>View recommended communities</Link>
    </div>
  ) : (
    <div className={'content-recommendations-container'}>
      <h3>{`This content is in the ${basicCommunityData?.community?.name} community.`}</h3>
      <p>Join now to receive invitations to community events and updates on new content.</p>
      <JoinCommunityButton
        communityId={communityId ?? ''}
        communityName={basicCommunityData?.community?.name ?? ''}
        logJoinClicked={(_, e) =>
          elementClicked(e, 'click-feed-recommended-communities-join', { communityId: communityId })
        }
      />
      {otherRecommended && <Link to={'/recommendedcommunities'}>View recommended communities</Link>}
    </div>
  )
}

type HomeFeedRecommendedViewProps = {
  loading: boolean
  recommendedCommunityIds?: string[]
  joinedCommunityIds: string[]
  onJoin?: () => void
}
const HomeFeedRecommendedView = ({
  loading,
  recommendedCommunityIds,
  joinedCommunityIds,
  onJoin,
}: HomeFeedRecommendedViewProps) => {
  const { isCondensedPortrait } = useWindowSize()
  const navigate = useNavigate()
  const { loadingMine } = useCommunities()
  const { loading: authLoading, companyId } = useAuth()
  const { data: userCompanyData, loading: companyLoading } = useQuery(GetCompanyNameDocument, {
    variables: { id: companyId ?? '' },
    skip: !companyId,
  })

  const companyName = userCompanyData?.company?.name

  const joinedCommunitiesLength =
    // Ideally, we want to show 2 joined communities and 4 recommended communities. However, if there are less than
    // 4 recommended communities, we can show more joined communities. Similarly, if there are less than 2 joined
    // communities we can show more recommended communities. Either way, there should be no more than 6 total
    (recommendedCommunityIds?.length ?? 0) >= 4
      ? Math.min(2, joinedCommunityIds?.length ?? 0)
      : Math.min(6 - (recommendedCommunityIds?.length ?? 0), 4)
  const recommendCommunitiesLength = 6 - joinedCommunitiesLength

  const handleDiscoverClicked = (e: SyntheticEvent, link: string) => {
    elementClicked(e, 'click-feed-discover-communities')
    navigate(link)
  }

  return (
    <div className={`recommended-container ${isCondensedPortrait ? 'condensed' : ''}`}>
      <h3>Welcome to Veeva Connect!</h3>
      {loading || loadingMine || authLoading || companyLoading ? (
        <>Loading suggested communities...</>
      ) : recommendedCommunityIds?.length ? (
        <>
          <h6>Based on your areas of interest, here are some recommended communities:</h6>
          <div className="communities-list">
            {joinedCommunityIds
              ?.slice(0, joinedCommunitiesLength)
              .map(communityId => <RecommendedCommunityItem key={communityId} communityId={communityId} />)}
            {recommendedCommunityIds
              ?.slice(0, recommendCommunitiesLength)
              .map(cId => <RecommendedCommunityItem key={cId} communityId={cId} onJoin={onJoin} />)}
          </div>
          {
            <Button className={'browse'} onClick={event => handleDiscoverClicked(event, '/recommendedcommunities')}>
              Discover Communities
            </Button>
          }
        </>
      ) : (
        <>
          <h6>To get the most out of Connect, join some Communities!</h6>
          <p>
            In addition to {companyName}'s Home, you'll find communities for specific products and areas of interest.
          </p>
          <Button className={'browse'} onClick={event => handleDiscoverClicked(event, '/communities')}>
            Discover Communities
          </Button>
        </>
      )}
    </div>
  )
}

type RecommendedCommunitiesProps = {
  singleContentView?: boolean
  setLoadingRecommended?: (b: boolean) => void
  onJoin?: () => void
}

const RecommendedCommunities = ({ singleContentView, setLoadingRecommended, onJoin }: RecommendedCommunitiesProps) => {
  const { authUserId, loading: authLoading } = useAuth()
  const { data: recommendedData, loading: recommendedLoading } = useQuery(GetSuggestedCommunitiesDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: userCommunityData, loading: userCommLoading } = useQuery(GetUserCommunitiesDocument, {
    variables: { id: authUserId ?? '' },
    skip: !authUserId,
    // if a user joins a recommended community, we do not want to update the user community data and recalculate
    // which communities to show while the user is still on this screen.
    nextFetchPolicy: 'standby',
  })

  const communityIds = useMemo(
    () =>
      (userCommunityData?.user?.memberships?.edges.map(e => e?.node?.communityId).filter(Boolean) ?? []) as string[],
    [userCommunityData]
  )

  const recommendedCommunityIds = useMemo(
    () =>
      recommendedData?.suggestedCommunities
        ?.map(c => ({
          communityId: c?.communityId ?? '',
          // randomly sort the communities, so they can be different each time
          sort: Math.random(),
        }))
        .sort((a, b) => a.sort - b.sort)
        .map(c => c.communityId)
        .filter(cId => !communityIds?.includes(cId)),
    [recommendedData, communityIds]
  )

  const loading =
    (authLoading && !authUserId) ||
    (userCommLoading && !userCommunityData) ||
    (recommendedLoading && !recommendedData) ||
    recommendedCommunityIds === undefined

  useEffect(() => {
    if (!loading && recommendedCommunityIds?.length > 0) {
      elementClickedNoEvent('show-feed-recommended-communities', { recommendedCommunityIds })
    }
  }, [loading, recommendedCommunityIds])

  return singleContentView ? (
    <SingleContentRecommendedView
      loading={loading}
      communityIds={communityIds}
      recommendedCommunityIds={recommendedCommunityIds}
      setLoadingRecommended={setLoadingRecommended}
    />
  ) : (
    <HomeFeedRecommendedView
      loading={loading}
      recommendedCommunityIds={recommendedCommunityIds}
      joinedCommunityIds={communityIds}
      onJoin={onJoin}
    />
  )
}

export default RecommendedCommunities
